import React, {useState} from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormText, Row, Col } from 'reactstrap';
import AutoComplete from 'User/compnents/AutoComplete';
import ScheduleTable from 'schedule/components/ScheduleTable';
import moment from 'moment';
import { DateTime } from 'luxon';
import AsyncPassengerSelect from 'schedule/components/AsyncPassengerSelect';
import DateTimePicker from 'react-datetime-picker';

window.moment = moment;
window.DateTime = DateTime;

function ScheduleModal({
    modal, 
    toggle, 
    className, 
    dropAddress, 
    setDropAddress, 
    handleDropSelect, 
    setFormData, 
    loadPassengers, 
    passengersList, 
    saveSchedule, 
    saveScheduleHandler,
    formData,
    temporaryData,
    mode,
    checkPno,
    removePassenger,
    validateForm,
    setValidateForm,
    locationTextToggle,
    setLocationTextToggle,
    changeDateToPast,
    driverProps,
    setArrivalFlag,
    setArrivalData
}) {
    let currentDate;
    if (mode?.edit) {
        currentDate = moment(formData?.pickDateTime).toString();
    }
    const [value, onChange] = useState(new Date);
    let validateClass = "";

    if(validateForm?.pickDateTime){
        validateClass='validator';
    }

    const resetValidatePassenger = () => {
        setValidateForm((prev) => {
            return {
            ...prev,
            passengerNo: false
            }
        })
    }


    const resetDropLocation = () => {
        setValidateForm((prev) => {
            return {
            ...prev,
            dropLocation: false
            }
        })
    }
    return (
        <Modal isOpen={modal} toggle={toggle} className={className} size="xl">
            <ModalHeader toggle={toggle}>Schedule</ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm="2">
                        <FormGroup>
                            <Label for="flight">Status</Label>
                            <select 
                                className={validateForm?.rideStatus ? 'is-invalid form-control form-control-sm': 'form-control form-control-sm'} 
                                onChange={(e) =>{
                                    setFormData(e.target.value, 'rideStatus');
                                    if (e.target.value === 'arrival') {
                                        setLocationTextToggle('Pick Location')
                                    }else {
                                        setLocationTextToggle('Drop Location')
                                    }
                                    // setValidateForm((prev) => {
                                    //     return {
                                    //         ...prev,
                                    //         rideStatus: e.target.value ? e.target.value : "departure"
                                    //     }
                                    // })
                                }}
                            >
                                {/* <option>Please select</option> */}
                                {[{title: 'Departure', value: 'departure'}, {title: 'Arrival', value: 'arrival'}].map(status=> <option value={status.value} selected={formData?.rideStatus === status.value}>{status.title}</option>)}
                            </select>
                        </FormGroup>
                    </Col>
                    <Col sm="2">
                        <FormGroup>
                            <Label for="flight">Flight Type</Label>
                            <select 
                                className='form-control form-control-sm' 
                                onChange={(e) =>{
                                    setFormData(e.target.value, 'flightType');
                                    setValidateForm((prev) => {
                                        return {
                                        ...prev,
                                        flightType: 3.0
                                        }
                                    })
                                }}
                            >
                                {[{title: 'ATR, Hotel', value: 3.0}, {title: '320+777', value: 3.15}, {title: 'Road, Asby', value: 2.0}].map(type=> <option value={type.value} selected={formData?.flightType === type.value}>{type.title}</option>)}
                            </select>
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup>
                            <Label for="flight">Flight No</Label>
                           
                            <Input 
                                type="text" 
                                size="sm"
                                name="flightNumber" 
                                id="flight" 
                                placeholder="Flight No"
                                className={validateForm?.flightNumber ? 'is-invalid': ''}
                                value={formData?.flightNumber}
                                onChange={(e) =>{
                                    setFormData(e.target.value, 'flightNumber');
                                    if(e.target.value && e.target.value.length >= 3 && !mode?.edit && formData?.rideStatus == 'arrival'){
                                        setArrivalData(e.target.value);
                                       // setLocationTextToggle('Pick Location');
                                     }
                                    setValidateForm((prev) => {
                                        return {
                                          ...prev,
                                          flightNumber: false
                                        }
                                    })
                                    
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup>
                            <Label for="date">Date</Label>
                            <DateTimePicker
                                onChange={(e) => {
                                    onChange(e);
                                    changeDateToPast(moment(e).format());
                                    setFormData(moment(e).format(), 'pickDateTime');
                                    setValidateForm((prev) => {
                                        return {
                                          ...prev,
                                          pickDateTime: false
                                        }
                                    })
                                }}
                                format="dd-MM-y HH:mm" value={formData && formData.pickDateTime ? new Date(formData?.pickDateTime) : value}
                                className={`d-flex ${validateClass}`}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm="2">
                        <FormGroup className="customer-autocomplete">
                            <Label for="passengerNo">P - No</Label>
                            <AsyncPassengerSelect 
                                setFormData={setFormData} 
                                formData={formData} 
                                resetValidate={resetValidatePassenger}
                                validateForm={validateForm}
                                passengersList={passengersList}
                            />
                        </FormGroup>
                    </Col>
                    <Col  sm="2">
                        <FormGroup>
                            <Label for="flight">Driver</Label>
                            <select
                                name="driver"
                                onChange={(e) =>{
                                    setFormData(e.target.value, 'driver');
                                    // setValidateForm((prev) => {
                                    //     return {
                                    //     ...prev,
                                    //     driver: false
                                    //     }
                                    // })
                                }}
                                id="driver"
                                className={validateForm?.driver ? 'is-invalid form-control form-control-sm': 'form-control form-control-sm'} >
                                <option value="">Please select</option>
                                {driverProps?.map(driver => <option value={driver._id} selected={formData?.driver === driver._id}>{driver.fullname}</option>)}
                            </select>
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup className="autocomplete-css">
                            <Label for="flight">{locationTextToggle}</Label>
                            <AutoComplete 
                                address={dropAddress} 
                                value={formData?.dropLocation} 
                                setAddress={setDropAddress} 
                                handleSelect={handleDropSelect}
                                resetValidation={resetDropLocation}
                                validateForm={validateForm}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup className="mt-4">
                            <Button color="info" block size="sm" onClick={loadPassengers}>Load Passenger</Button>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <ScheduleTable formData={formData} data={passengersList} removePassenger={removePassenger}/>
                </Row>
            </ModalBody>
            <div className="m-auto pb-4">
                <Button color="primary" size="sm" onClick={saveScheduleHandler}>{mode?.edit ? "Update" : "Save" }</Button>{' '}
                {/* <Button color="danger" size="sm" onClick={toggle}>Clear</Button>{' '} */}
                <Button color="secondary" size="sm" onClick={toggle}>Cancel</Button>
            </div>
        </Modal>
    )
}

export default ScheduleModal
