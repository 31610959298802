import React, {useState} from 'react'
import { Field, ErrorMessage, withFormik, Form } from 'formik';
import * as Yup from 'yup';
import {
    Label,
    FormGroup,
    Input,
    FormFeedback,
    ModalBody,
    Modal,
    Row, Col,
    ModalFooter,
    ModalHeader
} from 'reactstrap';
import AdminLTE from 'adminlte';

const InnerForm = ({
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    handleChange,
    modal,
    toggle,
    className,
    mode,
}) => {
    return (
        <Modal isOpen={modal} toggle={toggle} className={className} centered>
            <ModalHeader  toggle={toggle}>
                Add New Location/Code
            </ModalHeader>
            <Form onSubmit={handleSubmit} className="addressForm">
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="name">Area Name</Label>
                                <Input size="sm" tag={Field} name="name" id="name" placeholder="Area Name" className={touched && touched.name ? (errors && errors.name ? 'is-invalid' : 'is-valid') : ''} />
                                <ErrorMessage component={FormFeedback} name="name" />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="code">Code</Label>
                                <Input size="sm" tag={Field} name="code" id="code" placeholder="Code" className={touched && touched.code ? (errors && errors.code ? 'is-invalid' : 'is-valid') : ''} />
                                <ErrorMessage component={FormFeedback} name="code" />
                            </FormGroup>
                        </Col>
                    </Row>
                         
                    
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Input type="hidden" name="id" id="id" />
                        <AdminLTE.Button.Spinner 
                            block color="primary" 
                            isSubmitting={isSubmitting} 
                            disabled={isSubmitting} 
                            text={mode?.edit ? "Update" : "Submit"} 
                            type="submit" 
                            size="sm">
                            Saving Location...
                    </AdminLTE.Button.Spinner>
                    </div>
                </ModalFooter>
            </Form>
        </Modal>
    )
};

const LocationForm = withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ temporaryData }) => {
        return {
            name: temporaryData && temporaryData.name,
            code: temporaryData && temporaryData.code,
            id: temporaryData && temporaryData?._id
        }
    },
    validationSchema: Yup.object().shape({
        name: Yup.string().required("name is required"),
        code: Yup.string().required("code is required")
    }),
    handleSubmit: (values, { props: { submitHandler, closeModal }, setSubmitting, resetForm }) => {
        setSubmitting(true);
        submitHandler({payload: values, closeModal, setSubmitting, resetForm});
    },
})(InnerForm);


LocationForm.defaultProps = {}

export default LocationForm
