import React, {useState, useEffect} from 'react'
import PrivateTwoColumns from 'modules/layouts/PrivateTwoColumns';
import { connect } from 'react-redux';
import { Content } from 'adminlte';
import {
  Card,
  CardHeader,
  CardBody,
  Button
} from 'reactstrap';
import DataTableListing from 'pages/dataTable/DataTableListing';
import * as genericAction from 'generic/actions';
import PageLoading from 'components/page-loading/PageLoading';
import ScheduleModal from '@app/schedule/components/ScheduleModal';
import  {
    geocodeByAddress,
    getLatLng
} from 'react-places-autocomplete';
import produce from 'immer';
import * as actions from '../../actions';
import DeleteModal from 'pages/confirmation/DeleteModal';
import styled from 'styled-components';



// Filtering input box 

const TextField = styled.input`
	height: 32px;
	width: 200px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;
	&:hover {
		cursor: pointer;
	}
`;

const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 34px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
	<>
		<TextField
			id="search"
			type="text"
			placeholder="Filter By Name"
			aria-label="Search Input"
			value={filterText}
			onChange={onFilter}
		/>
		<ClearButton type="button" onClick={onClear}>
			X
		</ClearButton>
	</>
);


function Schedule({dispatch, formData, passengersList}) {
    const [appLoadingState, updateAppLoading] = useState(false);
    const [locationTextToggle, setLocationTextToggle] = useState('Drop Location');
    const [temporaryData, setTemporaryData] = useState({});
    const [driverProps, setdriverProps] = useState([]);
    const [mode, setMode] = useState({
      edit: false,
    });
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [dropAddress, setDropAddress] = useState("");
    const [dropCoordinates, setDropCoordinates] = useState({
        lat: null,
        lng: null
    });

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const [data, setData] = useState([]);

    const [uniqueId, setUniqueId] = useState(1);

    const [deleteModal, setDeleteModal] = useState(false);
    const deleteToggle = () => {
        setDeleteModal(!deleteModal)
    };
    
    const [validateForm, setValidateForm] = useState({
      flightNumber: false,
      date: false,
      status: false,
      passengerNo: false,
  
    });

    useEffect(() => {
      getScheduleAsync();
      getDriversAsync();
    },[]);

    const changeDateToPastHandler = (e) => {
      const {
        passengerNo,
        flightNumber,
        pickDateTime,
        rideStatus,
        flightType,
        dropLocation,
    
      } = formData;
      
      if (!flightNumber) {
        validate('flightNumber');
      }

      if (pickDateTime ==''){
        validate('pickDateTime');
      }

      if (!rideStatus){
        validate('rideStatus');
      }

      if (!dropLocation){
        validate('dropLocation');
      }

      if (!passengerNo){
        validate('passengerNo');
      }

      if (flightNumber && pickDateTime && passengerNo && rideStatus && dropLocation) {
        dispatch(actions.changeDateTimeToPast(e));
      }
    }
    
    const getScheduleAsync = () => {
        dispatch(genericAction.callGenericGetterAsync(`/admin/schedule?status=all`, (res) => {
            if (res?.data)
              setData(res?.data);
        }))
    }

    const validate = (validator) => {
      setValidateForm((prev) => {
        return {
          ...prev,
          [validator]: true
        }
      })
    }

    /* filtering the record from the table */ 

    const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText('');
        }
      };
      return (
        <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
      );
    }, [filterText, resetPaginationToggle]);
    /*And it's end here  */

    const loadPassengersAsync = () => {
        const {
          passengerNo,
          flightNumber,
          pickDateTime,
          rideStatus,
          driver,
          dropLocation
        } = formData;
        
        if (!flightNumber) {
          validate('flightNumber');
        }

        if (pickDateTime ==''){
          validate('pickDateTime');
        }

        if (rideStatus == ''){
          validate('rideStatus');
        }

        if (!dropLocation){
          validate('dropLocation');
        }

        if (!passengerNo){
          validate('passengerNo');
        }
        // if (!driver){
        //   validate('driver');
        // }

        if (flightNumber && pickDateTime && passengerNo && rideStatus && dropLocation) {
          
          dispatch(genericAction.callGenericGetterAsync(`/users/loadUser/${passengerNo}/${driver? driver : 'null'}`, (res) => {
              
              if (res?.data)
                var udata = res?.data?.user
                udata.flightTime = pickDateTime
                udata.driver = res?.data?.driver?._id
                udata.driver_name = res?.data?.driver?.fullname
                udata.flightTimeUpdated = ''
                dispatch(actions.setSchedulePassengersList(udata));
                const changePassenger = passengersList.map( (p, index) => {
                  if ( index > 0 ) {
                    if (p.driver === passengersList[index - 1].driver){
                      p = {
                        ...p,
                        driverShare: true
                      }
                    }
                  }else{
                    p = {
                      ...p,
                      driverShare: false
                    }
                  }

                  return p;
                });                
          }))
        }
    }

    const setFormData = (value, name) => {
        const producedData = produce(formData, drafState => {
            drafState[name] = value;
        })
        dispatch( actions.setScheduleFormData(producedData) );
    }
    const handleDropSelect = async (value) => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        setDropAddress( value );
        setDropCoordinates(latLng);
        setFormData(value, 'dropLocation');
    }

    const removePassenger = (index,delid) => {
      const newArr = passengersList?.map(passenger => passenger);
      newArr?.splice(index, 1);
      dispatch(actions.setScheduleUsers(newArr));
      if (delid) {
        dispatch(genericAction.callGenericAsync({}, `/admin/deleteTripUser/${delid}`, 'delete', (res) => {
          if(res) {
            getScheduleAsync();
          }
        }))
      }else {
        alert("Data not found.");
      }
    }

    const saveScheduleHandler = () => {
        const payload =  {
            pNo: formData?.passengerNo,
            driver: formData?.driver,
            // pickLocation: data?.location_tmp,
            dropLocation: dropAddress,
            flightNumber: formData?.flightNumber,
            picklat: data?.lat,
            pickLong: data?.lng,
            droplat: dropCoordinates?.lat,
            droplong: dropCoordinates?.lng,
            status: formData?.rideStatus,
            flightType: formData?.flightType,
            pickDateTime: formData?.pickDateTime,
            passenger: passengersList,
        }
        
      if (mode?.edit) {
          let passArr = [];
          passengersList.forEach(passenger => {
            if (passenger.pid){
              passArr.push(passenger)
            }else {
              passArr.push( passenger );
            }
          })
          
          const editPayload = {
            pNo: formData?.passengerNo,
            // pickLocation: data?.location_tmp,
            dropLocation: dropAddress,
            flightNumber: formData?.flightNumber,
            picklat: data?.lat,
            pickLong: data?.lng,
            droplat: dropCoordinates?.lat,
            droplong: dropCoordinates?.lng,
            status: formData?.rideStatus,
            flightType: formData?.flightType,
            pickDateTime: formData?.pickDateTime,
            passenger: passArr
          }

          dispatch(genericAction.callGenericAsync(editPayload, `/admin/admin-schedule-update/${formData.flightNumber}`, 'put', (res) => {
            if(res && res?.status ==1) {
                passArr = [];
                setMode({
                  edit: false
                });
                toggle();
                getScheduleAsync();
              }
          }))
        }else{
          dispatch(genericAction.callGenericAsync(payload, '/admin/schedule', 'post', (res) => {
            if(res?.status ==1) {
              setMode({
                edit: false
              });
              toggle();
              getScheduleAsync();
            }
        }))
        }

    }
    const getDriversAsync = () => {
      updateAppLoading(true);
      dispatch(genericAction.callGenericGetterAsync('/admin/drivers/', (res) => {
        if (res) {
          setdriverProps(res?.data);
          updateAppLoading(false);
        }
      }));
    }
    const getScheduleByIdAsync = (id) => {
      dispatch(genericAction.callGenericAsync({}, `/admin/schedule/edit/${id}`, 'get', (res) => {
        if(res) {
          dispatch(actions.setScheduleUsers(res?.data?.passenger));
          setTemporaryData(res?.data);
          dispatch( actions.setScheduleFormData({
            id: res?.data?._id,
            flightNumber: res?.data?.flightNumber,
            passengerNo: res?.data?.pNo,
            dropLocation: res?.data?.dropLocation,
            pickDateTime: res?.data?.pickDateTime,
            rideStatus: res?.data?.status,
            flightType: res?.data?.flightType
          }) );
        }
      }))
    }
    
    const setArrivalData = (value) => {
      dispatch(genericAction.callGenericAsync({}, `/admin/schedule/existingFlight/${value}`, 'get', (res) => {
        if(res && res?.status ==1, res?.data?.passenger.length > 0 ) {
          dispatch(actions.setScheduleUsers(res?.data?.passenger));
          setTemporaryData(res?.data);
          const coordinates = {
            lat: res?.data?.droplat,
            lng: res?.data?.droplong
          }
          setDropCoordinates(coordinates)
          
          dispatch( actions.setScheduleFormData({
            id: res?.data?._id,
            passengerNo: res?.data?.pNo,
            dropLocation: res?.data?.dropLocation,
            pickDateTime: res?.data?.pickDateTime,
            rideStatus: 'arrival',
            driver: res?.data?.passenger[0]?.driver,
          }));
          setDropAddress(res?.data?.dropLocation)
        }
      }))
    }
    const deleteCustomerHandler = () => {
        if (uniqueId) {
          dispatch(genericAction.callGenericAsync({}, `/admin/schedule/${uniqueId}`, 'delete', (res) => {
            if(res) {
              getScheduleAsync();
            }
          }))
        }else {
          alert("Data not found.");
        }
    }

    const columns = [
      {
        name: "Flight #",
        selector: "flightNumber",
        width: "90px",
        sortable: true
    },
    {
      name: "P Time",
      width: "130px",
      cell: row => moment(row.pickDateTime).format('DD-MM-yyyy HH:mm'),
    //  selector: "pickDateTime",
      sortable: true
  },
    {
        name: "Drop Location",
        selector: "dropLocation",
        width: "320px",
        sortable: true,
    },
    {
        name: "Passengers",
        width: "130px",
        selector: (row) => {
            return row?.passenger;
        },
        sortable: true
    },
    {
        name: "Flight",
        width: "150px",
        selector: (row) => {
          return row?.status;
        },
        sortable: true
    },
        {
          name: "Action",
            cell: (data) => {
              return (<i className="text-info fas fa-edit"  onClick={() => {
                getScheduleByIdAsync(data?.flightNumber)
                const coordinates = {
                  lat: data?.droplat,
                  lng: data?.droplong
                }
                setDropCoordinates(coordinates);
                dispatch( actions.setScheduleFormData({
                  id: data?._id,
                  flightNumber: data?.flightNumber,
                  flightType: data?.flightType,
                  passengerNo: data?.pNo,
                  dropLocation: data?.dropLocation,
                  pickDateTime: data?.pickDateTime,
                  rideStatus: data?.status
                }) );
                setDropAddress(data?.dropLocation)
                setMode({
                  edit: true
                });
                toggle();
              }}/>)
          },
        width: "20px",
        },
        {
          name: "",
            cell: (data) => {
              return (<i className="text-danger fas fa-trash-alt" onClick={() => {
                setUniqueId(data?.flightNumber);
                deleteToggle();
              }}/>)
          },
        width: "20px",
        }
    ];
    const filteredItems = data.filter(
      item => item.flightNumber && item.flightNumber.toLowerCase().includes(filterText.toLowerCase()),
    );
    return (
        <>
        {appLoadingState ? <PageLoading /> :
        <PrivateTwoColumns pageTitle="Schedule">
            <Content>
                {/* <Content.Header>
                <Content.Title>Schedule Listing</Content.Title>
                </Content.Header> */}
                <Content.Wrapper>
                <Card className="card-info card-outline">
                    <CardHeader>
                    <div>
                        <h3 class="card-title">All Schedules</h3>
                    </div>
                    <div className="text-right">
                        <Button color="info" size="sm" onClick={() => {
                            toggle();
                            setMode({
                              edit: false
                            });
                            dispatch(actions.setScheduleUsers());
                            setDropAddress('')
                            dispatch(actions.setScheduleFormData({
                              flightNumber: '',
                              passengerNo: '',
                              driver: '',
                              dropLocation: '',
                              pickDateTime: new Date(),
                              flightType: "3.0",
                              rideStatus: 'departure'
                            }));
                        }}>+ Add new Schedule</Button>
                    </div>
                    </CardHeader>
                    <CardBody>
                    <DataTableListing defaultSortAsc={false} columns={columns} data={data} title="" subHeaderComponent={subHeaderComponentMemo}/>
                    </CardBody>
                </Card>
                </Content.Wrapper>
                <ScheduleModal 
                    modal={modal} 
                    toggle={toggle} 
                    handleDropSelect={handleDropSelect} 
                    dropAddress={dropAddress}
                    setDropAddress={setDropAddress}
                    setFormData={setFormData}
                    loadPassengers={loadPassengersAsync}
                    passengersList={passengersList?.length > 0 ? passengersList : []}
                    saveScheduleHandler={saveScheduleHandler}
                    temporaryData={temporaryData}
                    formData={formData}
                    setArrivalData={setArrivalData}
                    mode={mode}
                    removePassenger={removePassenger}
                    validateForm={validateForm}
                    setValidateForm={setValidateForm}
                    locationTextToggle={locationTextToggle}
                    setLocationTextToggle={setLocationTextToggle}
                    changeDateToPast={changeDateToPastHandler}
                    driverProps={driverProps}
                />
            </Content>
            <DeleteModal modal={deleteModal} toggle={deleteToggle} deleteCustomer={deleteCustomerHandler}/>
        </PrivateTwoColumns>
        }
        </>
    )
}

const mapStateToProps = ({ auth, schedule }) => ({
  ...auth,
  ...schedule
})

export default connect(mapStateToProps)(Schedule);