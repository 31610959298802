import firebase from 'firebase/app';
import 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyBgo3C15thD2WZVSa2ZzPDsOvNUeFR42pg",
    authDomain: "khizar-driver.firebaseapp.com",
    databaseURL: "https://khizar-driver-default-rtdb.firebaseio.com",
    projectId: "khizar-driver",
    storageBucket: "khizar-driver.appspot.com",
    messagingSenderId: "653557223572",
    appId: "1:653557223572:web:bb7861a7b9089e9422e481",
    measurementId: "G-YDNHTP8LY6"
};
firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();

export const getToken = (setTokenFound,setFcmToken) => {
  return messaging.getToken({vapidKey: 'BDhAnX7-dx5mXx5ioRiQ8KxEcJqSvhByqV2CgOWLeHf2d1Zpo372wSRfRnc5j96YEwZsCueYezrX_Wx0d67yyiw'}).then((currentToken) => {
    if (currentToken) {
     // console.log('current token for client: ', currentToken);
      setFcmToken(currentToken);
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setFcmToken('');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
});