import React, {useState} from 'react'
import { Field, ErrorMessage, withFormik, Form } from 'formik';
import * as Yup from 'yup';
import {
    Label,
    FormGroup,
    Input,
    FormFeedback,
    ModalBody,
    Modal,
    Row, Col,
    ModalFooter,
    ModalHeader
} from 'reactstrap';
import FileUpload from 'utils/FileUpload';
import AdminLTE from 'adminlte';
import AutoComplete from 'User/compnents/AutoComplete';



const InnerForm = ({
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    handleChange,
    modal,
    toggle,
    className,
    pickAddress,
    setPickAddress,
    handlePickSelect,
    locationCodeProps,
    // assignedPassengerProps,
    statusProps,
    mode,
    temporaryData,
    setThumbPath,
    setFilePath,
    thumbPath,
}) => {
    return (
        <Modal isOpen={modal} toggle={toggle} className={className} centered>
            <ModalHeader  toggle={toggle}>
                Add New Driver
            </ModalHeader>
            <Form onSubmit={handleSubmit} className="addressForm">
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="fullname">Full Name</Label>
                                <Input size="sm" tag={Field} name="fullname" id="fullname" placeholder="Fullname" className={touched && touched.fullname ? (errors && errors.fullname ? 'is-invalid' : 'is-valid') : ''} />
                                <ErrorMessage component={FormFeedback} name="fullname" />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input size="sm" tag={Field} name="email" id="email" placeholder="Email" className={touched && touched.email ? (errors && errors.email ? 'is-invalid' : 'is-valid') : ''} />
                                <ErrorMessage component={FormFeedback} name="email" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="password">Password</Label>
                                <Input size="sm" tag={Field} name="password" id="password" placeholder="password" className={touched && touched.password ? (errors && errors.password ? 'is-invalid' : 'is-valid') : ''} />
                                <ErrorMessage component={FormFeedback} name="password" />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="cell">Cell</Label>
                                <Input size="sm" tag={Field} name="cell" id="cell" placeholder="Pick From" className={touched && touched.cell ? (errors && errors.cell ? 'is-invalid' : 'is-valid') : ''} />
                                <ErrorMessage component={FormFeedback} name="cell" />
                            </FormGroup>
                        </Col>
                    </Row>


                    <Row>
                        <Col>
                            <FormGroup className="autocomplete-css">
                                <Label for="location">Location</Label>
                                <AutoComplete address={pickAddress} setAddress={setPickAddress} handleSelect={handlePickSelect} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="vehicle_no">Vehicle No</Label>
                                <Input size="sm" tag={Field} name="vehicle_no" id="vehicle_no" placeholder="Vehicle No e.g. LED-4156" className={touched && touched.vehicle_no ? (errors && errors.vehicle_no ? 'is-invalid' : 'is-valid') : ''} />
                                <ErrorMessage component={FormFeedback} name="vehicle_no" />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="vehicle_capacity">Vehicle Capacity</Label>
                                <Input size="sm" tag={Field} name="vehicle_capacity" id="vehicle_capacity" placeholder="Vehicle Capacity" className={touched && touched.vehicle_capacity ? (errors && errors.vehicle_capacity ? 'is-invalid' : 'is-valid') : ''} />
                                <ErrorMessage component={FormFeedback} name="vehicle_capacity" />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="vehicle_model">Vehicle Model</Label>
                                <Input size="sm" tag={Field} name="vehicle_model" id="vehicle_model" placeholder="Vehicle Model e.g. Wagon R" className={touched && touched.vehicle_model ? (errors && errors.vehicle_model ? 'is-invalid' : 'is-valid') : ''} />
                                <ErrorMessage component={FormFeedback} name="vehicle_model" />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        {/* <Col>
                            <FormGroup>
                                <Label for="area">Area</Label>
                                <select
                                    name="area"
                                    id="area"
                                    size="sm"
                                    onChange={handleChange}
                                    className={touched && touched.location_code ? (errors && errors.location_code ? 'is-invalid form-control form-control-sm' : 'is-valid form-control form-control-sm') : 'form-control form-control-sm'}>
                                    <option value="">Please select</option>
                                    {locationCodeProps?.map(location => <option value={location._id} selected={temporaryData?.area === location._id}>{location.name}</option>)}
                                </select>

                            </FormGroup>
                        </Col> */}
                        {/* <Col>
                            <FormGroup>
                                <Label for="assignedPassenger">Assigned Passenger</Label>
                                <select
                                    name="assignedPassenger"
                                    id="assignedPassenger"
                                    bsSize="sm"
                                    onChange={handleChange}
                                    className={touched && touched.assignedPassenger ? (errors && errors.assignedPassenger ? 'is-invalid form-control form-control-sm' : 'is-valid form-control form-control-sm') : 'form-control form-control-sm'}>
                                    <option value="">Please select</option>
                                    {assignedPassengerProps?.map(assignedPassenger => <option value={assignedPassenger.value} selected={temporaryData?.assignedPassenger == assignedPassenger.value }>{assignedPassenger.title}</option>)}
                                </select>
                            </FormGroup>
                        </Col> */}
                        <Col>
                            <FormGroup>
                            <Label for="status">Status</Label>
                                <select
                                    name="status"
                                    id="status"
                                    bsSize="sm"
                                    onChange={handleChange}
                                    className={touched && touched.status ? (errors && errors.status ? 'is-invalid form-control form-control-sm' : 'is-valid form-control form-control-sm') : 'form-control form-control-sm'}>
                                    {/* <option value="">Please select</option> */}
                                    {statusProps?.map(status => <option value={status.value} selected={ temporaryData && temporaryData?.status == status.value }>{status.title}</option>)}
                                </select>
                            </FormGroup>
                        </Col>
                    </Row>

                    
                    <Row>
                        <Col>
                            <FileUpload
                                name="driver_file"
                                isSubmitting={false}
                                filePath={thumbPath ? thumbPath : 'https://via.placeholder.com/1000x200'}
                                endpoint={`${process.env.REACT_APP_API_BASE_URL}/driver/uploads`}
                                responseCallback={(res) => {
                                    setThumbPath(res.data.thumbnails);
                                    setFilePath(res.data.filePath);
                                }}
                                setFieldValue={(name, file) => console.log(name, '=>', file)}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Input type="hidden" name="id" id="id" />
                        <AdminLTE.Button.Spinner 
                            block color="primary" 
                            isSubmitting={isSubmitting} 
                            disabled={isSubmitting} 
                            text={mode?.edit ? "Edit Driver" : "Add Driver"} 
                            type="submit" 
                            size="sm">
                            Saving Driver...
                    </AdminLTE.Button.Spinner>
                    </div>
                </ModalFooter>
            </Form>
        </Modal>
    )
};

const DriverForm = withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ temporaryData, locCoordinates, pickCoordinates }) => {
        return {
            fullname: temporaryData && temporaryData.fullname,
            email: temporaryData && temporaryData.email,
            // password: temporaryData && temporaryData.password,
            cell: temporaryData && temporaryData?.cell,
            // assignedPassenger: temporaryData && temporaryData.assignedPassenger,
            vehicle_no: temporaryData && temporaryData?.vehicle_no,
            vehicle_model: temporaryData && temporaryData.vehicle_model,
            vehicle_capacity: temporaryData && temporaryData.vehicle_capacity,
            area: temporaryData && temporaryData?.area,
            status: temporaryData && temporaryData.status,
            id: temporaryData && temporaryData?._id
        }
    },
    validationSchema: Yup.object().shape({
        fullname: Yup.string().required("Fullname is required"),
        email: Yup.string().email().required("Email is required"),
        // password: Yup.string().required("Password is required"),
        cell: Yup.string().required("Cell is required"),
        // assignedPassenger: Yup.string().required("Assigned Passenger is required"),
        vehicle_no: Yup.string().required("Vehicle No is required"),
        vehicle_model: Yup.string().required("Vehicle Model is required"),
        vehicle_capacity: Yup.string().required("Vehicle Capacity is required"),
        // area: Yup.string().required("Area is required"),
        // status: Yup.string().required("Status is required"),
    }),
    handleSubmit: (values, { props: { submitHandler, closeModal, pickCoordinates, locCoordinates }, setSubmitting, resetForm }) => {
        values.address = pickCoordinates;
        values.address_tmp = locCoordinates;
        values.status = values.status ? values.status : 1;
        setSubmitting(true);
        submitHandler({payload: values, closeModal, setSubmitting, resetForm});
    },
})(InnerForm);


export default DriverForm
