import React from 'react'
import DataTable from "react-data-table-component";


function DataTableListing({title, data, columns,pagination,paginationServer,paginationTotalRows,onChangeRowsPerPage,onChangePage,sortServer,onSort , subHeader , subHeaderComponent,paginationResetDefaultPage,progressPending,defaultSortAsc,defaultSortField}) {
    return (
       
    <>
        <DataTable
            highlightOnHover
            pointerOnHover
            striped
            // title={title}
            columns={columns}
            data={data}
            defaultSortField="flightNumber"
            progressPending={progressPending}
            pagination={pagination}
            paginationServer={paginationServer}
            paginationTotalRows={paginationTotalRows}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangePage={onChangePage}
            sortServer={sortServer}
            onSort={onSort}
           // defaultSortField={defaultSortField}
            defaultSortAsc={false}
            subHeader={subHeader}
			subHeaderComponent={subHeaderComponent}
            paginationResetDefaultPage={paginationResetDefaultPage}
        />
    </>    
    )
}

DataTableListing.defaultProps = {
    title: "Listing",
    pagination: true,
    subHeader: true,
    sortServer: true 
}

export default DataTableListing
