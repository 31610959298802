import React, {useState, useEffect} from 'react';
import PrivateTwoColumns from 'modules/layouts/PrivateTwoColumns';
import { connect } from 'react-redux';
import { Content } from 'adminlte';
import {
  Card,
  CardHeader,
  CardBody,
  Button
} from 'reactstrap';
import LocationForm from 'locations/compnents/LocationForm';
import DataTableListing from 'pages/dataTable/DataTableListing';

import * as genericAction from 'generic/actions';
import DeleteModal from 'pages/confirmation/DeleteModal';
import PageLoading from 'components/page-loading/PageLoading';

function Location({dispatch}) {
  const [appLoadingState, updateAppLoading] = useState(false);
  const [temporaryData, setTemporaryData] = useState({});
  const [mode, setMode] = useState({
    edit: false,
  });
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [deleteModal, setDeleteModal] = useState(false);
  const deleteToggle = () => {
    setDeleteModal(!deleteModal)
  };

  const [uniqueId, setUniqueId] = useState(1);
  
  const [data, setData] = useState([]);

  useEffect(() => {
    if (data.length === 0) {
      getAsync();
    }
  }, [])

  const getAsync = () => {
    updateAppLoading(true);
    dispatch(genericAction.callGenericGetterAsync('/admin/locations/', (res) => {
      if (res) {
        setData(res?.data);
        updateAppLoading(false);
      }
    }));
  }

  const deleteHandler = () => {
      if (uniqueId) {
        dispatch(genericAction.callGenericAsync({}, `/admin/locations/${uniqueId}`, 'delete', (res) => {
          if(res) {
            getAsync();
          }
        }))
      }else {
        alert("Data not found.");
      }
  }

  const submitHandler = ({payload, closeModal, setSubmitting, resetForm }) => {

    
    if (mode?.edit) {
      dispatch(genericAction.callGenericAsync(payload, `/admin/locations/${payload.id}`, 'put', (res) => {
        setSubmitting(false);
        if(res?.status) {
          toggle();
          resetForm();
          getAsync();
          setTemporaryData({})
        }
      }))
    } else {
      dispatch(genericAction.callGenericAsync(payload, '/admin/locations/', 'post', (res) => {
        setSubmitting(false);
        if(res?.status) {
          toggle();
          resetForm();
          getAsync();
          setTemporaryData({})
        }
      }))
    }
  }

  const columns = [
    {
        name: "Area Name",
        selector: "name",
        sortable: true
    },
    {
        name: "Code",
        selector: "code",
        sortable: true,
    },
    {
      name: "Action",
        cell: (data) => {
          return (<i className="text-info fas fa-edit"  onClick={() => {
            setTemporaryData(data);
            setMode({
              edit: true
            });
            toggle();
          }}/>)
      },
      maxWidth: "5%",
    },
    {
      name: "",
        cell: (data) => {
          return (<i className="text-danger fas fa-trash-alt" onClick={() => {
            setUniqueId(data?._id);
            deleteToggle();
          }}/>)
      },
      maxWidth: "5%",
    }
  ];

  

  return (
    <>
    {appLoadingState ? <PageLoading /> :
      <PrivateTwoColumns pageTitle="Locations">
        <Content>
            {/* <Content.Header>
              <Content.Title> Location</Content.Title>
            </Content.Header> */}
            <Content.Wrapper>
              <Card className="card-info card-outline">
                <CardHeader>
                  <div>
                    <h3 class="card-title">Location Information</h3>
                  </div>
                  <div className="text-right">
                    <Button color="info" size="sm" onClick={() => {
                      toggle()
                      setMode({
                        edit: false
                      });
                      setTemporaryData({});
                    }}>+ Add New Location</Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <LocationForm 
                    modal={modal} 
                    toggle={toggle}
                    submitHandler={submitHandler}
                    temporaryData={temporaryData}
                    mode={mode}
                  />
                  <DataTableListing columns={columns} data={data} title="Locations Listing" />
                </CardBody>
              </Card>
            </Content.Wrapper>
            <DeleteModal modal={deleteModal} toggle={deleteToggle} deleteCustomer={deleteHandler}/>
        </Content>
      </PrivateTwoColumns>
    }
    </>
  );
}


const mapStateToProps = ({ auth }) => {
  return {
    ...auth
  }
}
export default connect(mapStateToProps)(Location);