import React, {useState, useEffect} from 'react';
import SmallBox from '../components/small-box/SmallBox';
import { connect } from 'react-redux';

import * as genericAction from 'generic/actions';

function Dashboard({dispatch}) {
  const [appLoadingState, updateAppLoading] = useState(false);
  const [temporaryData, setTemporaryData] = useState({});
  const [driverData, setdriverData] = useState([]);
  const [customerData, setcustomerData] = useState([]);
  const [runningRidesData, setRunningRidesData] = useState([]);
  const [todayRidesData, settodayRidesData] = useState([]);
 

  const [data, setData] = useState([]);

  useEffect(() => {
    if (data.length === 0) {
      getDrivers(1);
      getCustomers(1);
      getRunningScheduleAsync(1);
      getTodayScheduleAsync(1);
    }
  }, [])

  const getDrivers = page => {
    // updateAppLoading(true);
    dispatch(genericAction.callGenericGetterAsync(`/driver/?page=${page}&per_page=1&delay=1`, (res) => {
      if (res) {
        setdriverData(res?.total);
      }
    }));
  }
  const getCustomers = page => {
    // updateAppLoading(true);
    dispatch(genericAction.callGenericGetterAsync(`/users/?page=${page}&per_page=1&delay=1`, (res) => {
      if (res) {
        setcustomerData(res?.total);
      }
    }));
  }
    const getRunningScheduleAsync = () => {
        dispatch(genericAction.callGenericGetterAsync(`/admin/schedule?status=pending`, (res) => {
            if (res?.data)
            setRunningRidesData(res?.total);
        }))
    }
    const getTodayScheduleAsync = () => {
        dispatch(genericAction.callGenericGetterAsync(`/admin/schedule?status=all`, (res) => {
            if (res?.data)
            settodayRidesData(res?.total);
        }))
    }
 
   
  return (
    <>
    {/* {appLoadingState ? <PageLoading /> : */}
      <div className="container-fluid">
      <div className="row">
          <div className="col-lg-3 col-6">
              <SmallBox
                  count={customerData}
                  title="Customers"
                  type="info"
                  icon="ion-android-people"
                  navigateTo="/user"
              />
          </div>
          <div className="col-lg-3 col-6">
              <SmallBox
                  count={driverData}
                  title="Drivers"
                  type="success"
                  navigateTo="/drivers"
              />
          </div>
          <div className="col-lg-3 col-6">
              <SmallBox
                  count={runningRidesData}
                  title="Pending Rides"
                  type="warning"
                  navigateTo="/schedule-pending"
              />
          </div>
          <div className="col-lg-3 col-6">
              <SmallBox
                  count={todayRidesData}
                  title="All Schedules"
                  type="danger"
                  navigateTo="/schedule"
              />
          </div>
      </div>
  </div>
    
    </>
  );
}


const mapStateToProps = ({ auth }) => {
  return {
    ...auth
  }
}
export default connect(mapStateToProps)(Dashboard);
