import React, {useState, useEffect} from 'react'
import PrivateTwoColumns from 'modules/layouts/PrivateTwoColumns';
import { connect } from 'react-redux';
import { Content } from 'adminlte';
import {
  Card,
  CardHeader,
  CardBody,
  Button
} from 'reactstrap';
import DataTableListing from 'pages/dataTable/DataTableListing';
import * as genericAction from 'generic/actions';
import PageLoading from 'components/page-loading/PageLoading';
import ScheduleModal from '@app/schedule/components/DriverAssignModal';
import  {
    geocodeByAddress,
    getLatLng
} from 'react-places-autocomplete';
import produce from 'immer';
import * as actions from '../../actions';
import DeleteModal from 'pages/confirmation/DeleteModal';
import { DateTime } from 'luxon';


function Unassigned({dispatch, formData, passengersList}) {
    const [driversProps, setdriversProps] = useState([]);

    const [appLoadingState, updateAppLoading] = useState(false);
    const [locationTextToggle, setLocationTextToggle] = useState('Drop Location');
    const [temporaryData, setTemporaryData] = useState({});
    const [mode, setMode] = useState({
      edit: false,
    });
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [dropAddress, setDropAddress] = useState("");
    const [dropCoordinates, setDropCoordinates] = useState({
        lat: null,
        lng: null
    });
    const [data, setData] = useState([]);

    const [uniqueId, setUniqueId] = useState(1);

    const [deleteModal, setDeleteModal] = useState(false);
    const deleteToggle = () => {
        setDeleteModal(!deleteModal)
    };
    
    const [validateForm, setValidateForm] = useState({
      flightNumber: false,
      date: false,
      status: false,
      passengerNo: false,
    });

    const getScheduleAsync = () => {
        dispatch(genericAction.callGenericGetterAsync(`/admin/unassignedRides`, (res) => {
            if (res?.data)
                setData(res?.data);
        }))
    }



    useEffect(() => {
        getScheduleAsync();
        getDriversAsync();
    },[]);

    const submitHandler = ({payload, closeModal, setSubmitting,    }) => {
    //const saveScheduleHandler = () => {
        // const payload =  {
        //     pNo: temporaryData?.driver
        // }
        dispatch(genericAction.callGenericAsync(payload, `/admin/assign-driver/${payload.id}`, 'put', (res) => {
            if(res) {
                setSubmitting(false);
                getScheduleAsync();
                getDriversAsync();
                toggle();
                resetForm();
            }
        }))
     
    }

    const getDriversAsync = () => {
        updateAppLoading(true);
        dispatch(genericAction.callGenericGetterAsync('/driver?per_page=30', (res) => {
          if (res) {
            setdriversProps(res?.data);
            updateAppLoading(false);
          }
        }));
      }
    const deleteCustomerHandler = () => {
        if (uniqueId) {
          dispatch(genericAction.callGenericAsync({}, `/admin/deleteTripUser/${uniqueId}`, 'delete', (res) => {
            if(res) {
              getScheduleAsync();
            }
          }))
        }else {
          alert("Data not found.");
        }
    }

    const columns = [
        {
            name: "Flight Number",
            selector: "scheduleInfo.flightNumber",
            // sortable: true,
            // maxWidth: "4%",
        },
        
        {
          name: "P-No",
          selector: "passenger.staff_no",
          // sortable: true,
          // maxWidth: "4%",
        },
        {
            name: "Name",
            selector: "passenger.fullname",
            // sortable: true,
            // maxWidth: "15%",
        },
        {
          name: "Driver",
          // selector: "driver.fullname",
          selector: (row) => {
            return row?.driver?.fullname;
          },
          // sortable: true,
          // maxWidth: "15%",
        },
        {
          name: "Ride Status",
          selector: "status",
          // sortable: true,
          // maxWidth: "4%",
        },
        // {
        //     name: "Pick Address",
        //     selector: "start_address",
        //     sortable: true,
        //    // minWidth: "10%",
        //      maxWidth: "40%",
        // },
        {
          name: "Action",
         
            cell: (data) => {
              return (<i className="text-info fas fa-shuttle-van"  onClick={() => {
                setTemporaryData(data);
                setMode({
                  edit: true
                });
                toggle();
              }}/>)
          },
          maxWidth: "3%",
          minWidth: "3%",
        },
        {
          name: "",
            cell: (data) => {
              return (<i className="text-danger fas fa-trash-alt" onClick={() => {
                setUniqueId(data?._id);
                deleteToggle();
              }}/>)
          },
          maxWidth: "5%",
          minWidth: "9%",

        }
    ];

    return (
        <>
        {appLoadingState ? <PageLoading /> :
        <PrivateTwoColumns pageTitle="Schedule">
            <Content>
                <Content.Header>
                <Content.Title>Create Schedule</Content.Title>
                </Content.Header>
                <Content.Wrapper>
                <Card className="card-info card-outline">
                    <CardHeader>
                    <div>
                        <h3 class="card-title">Schedule Information</h3>
                    </div>
                    </CardHeader>
                    <CardBody>
                    <DataTableListing columns={columns} data={data} title="Schedule Listing" />
                    </CardBody>
                </Card>
                </Content.Wrapper>
                <ScheduleModal 
                    modal={modal} 
                    toggle={toggle}
                    driversProps={driversProps}
                    submitHandler={submitHandler}
                    temporaryData={temporaryData}
                    mode={mode}
                />
            </Content>
            <DeleteModal modal={deleteModal} toggle={deleteToggle} deleteCustomer={deleteCustomerHandler}/>
        </PrivateTwoColumns>
        }
        </>
    )
}

const mapStateToProps = ({ auth, schedule }) => {
    return {
      ...auth,
      ...schedule
    }
  }
  export default connect(mapStateToProps)(Unassigned);