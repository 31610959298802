import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import CanUseDom from 'can-use-dom';

const PublicRoute = ({children, isLoggedIn, ...rest}) => {
    const isAuthenticated = isLoggedIn || localStorage.getItem('token');
    const redirect = () => {
        if(CanUseDom)
            window.location = '/dashboard';
    }

    return (
        <Route
            {...rest}
            render={({location}) =>
                isAuthenticated ? (
                    redirect()
                ) : (
                    children
                )
            }
        />
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn
});

export default connect(mapStateToProps)(PublicRoute);
