import React from 'react'
import DataTableListing from 'pages/dataTable/DataTableListing';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,  } from 'reactstrap';
function credit(item){
    return item.credit;
}
function debit(item){
    return item.debit;
}
  
function sum(prev, next){
return prev + next;
}

function CashModal({className, modal, toggle, data}) {
    const columns = [
        {
            name: "Date",
            selector: "date",
            sortable: false,
        },{
            name: "Credit",
            selector: "credit",
            right: true,
            sortable: false,
        },{
            name: "Debit",
            selector: "debit",
            right: true,
            sortable: false
        }
       
    ];

    const totalCredit = (data && data.length > 0) ? data.map(credit).reduce(sum) : 0;
    const totalDebit = (data && data.length > 0) ? data.map(debit).reduce(sum) : 0;

    return (
       
        <div>
           <Modal isOpen={modal} toggle={toggle} className={className} size="md">
                <ModalHeader toggle={toggle}>Debit/Credit Information</ModalHeader>
                <ModalBody>
                    <DataTableListing title="" columns={columns} data={data}  pagination={false} />
                    
                    <br></br>
                    <p align="right"><b>Total In Wallet: {totalCredit - totalDebit}</b></p>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal> 
        </div>
    )
}

export default CashModal
