import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col,FormGroup,Label,Input,FormText  } from 'reactstrap';
import FileUpload from 'utils/FileUpload';
import { Field, ErrorMessage, withFormik, Form } from 'formik';
import * as Yup from 'yup';
import AdminLTE from 'adminlte';

const InnerForm = ({
    errors,
    isSubmitting,
    handleSubmit,
    modal,
    toggle,
    className,
    setThumbPath,
    setFilePath,
    thumbPath,
}) => {
    return (

// function BulkModal({className, modal, toggle, setThumbPath, setFilePath}) {
  
//     return (
       
        <div>
           <Modal isOpen={modal} toggle={toggle} className={className} size="md">
                <ModalHeader toggle={toggle}>Bulk Upload</ModalHeader>
                <Form onSubmit={handleSubmit} className="addressForm">
                    <ModalBody>
                        <Row>
                            <Col>
                            
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <FormGroup>
                                <FileUpload
                                    name="driver_file"
                                    isSubmitting={false}
                                    filePath={thumbPath ? thumbPath : 'https://via.placeholder.com/1000x200'}
                                    endpoint={`${process.env.REACT_APP_API_BASE_URL}/users/bulk-uploads`}
                                    responseCallback={(res) => {
                                        console.log(res.data.filePath)
                                      //  setThumbPath(res.data.thumbnails);
                                        setFilePath(res.data.filePath);
                                    }}
                                    setFieldValue={(name, file) => console.log(name, '=>', file)}
                                />
                            </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <div className="text-right">
                            <Button color="primary">Download Sample</Button>{' '}
                            <AdminLTE.Button.Spinner 
                                color="success" 
                                isSubmitting={isSubmitting} 
                                disabled={isSubmitting} 
                                text={"Upload"} 
                                type="submit" >
                                Uploading Customers...
                            </AdminLTE.Button.Spinner>
                            <Button  className='ml-1' color="danger" onClick={toggle}>Close</Button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal> 
        </div>
    )
}


const BulkModal = withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ temporaryData }) => {
        return { }
    },
    validationSchema: Yup.object().shape({  }),
    handleSubmit: (values, { props: { submitBulkHandler, closeModal }, setSubmitting, resetForm }) => {
        setSubmitting(true);
        submitBulkHandler({payload: values, closeModal, setSubmitting, resetForm});
    },
})(InnerForm);

export default BulkModal
