import * as ActionTypes from './actions';
import produce from "immer";
import moment from 'moment';

const initialState = {
    formData: {
        passengerNo: '',
        flightNumber: '',
        dropLocation: '',
        dropLat: '',
        dropLong: '',
        pickDateTime: '',
        pickDate: '',
        passenger: [],
    }
}

const ScheduleReducer = (state = initialState, action) => {
    try{
        switch (action.type) {
            case ActionTypes.SET_SCHEDULE_FORM_DATA:
                return {
                    ...state,
                    formData: action.payload
                }

            case ActionTypes.SET_SCHEDULE_PASSENGERS:
                return {
                    ...state,
                    passengersList: action.payload
                }

            case ActionTypes.SET_SCHEDULE_USERS:
                return {
                    ...state,
                    passengersList: action.payload
                }

            case ActionTypes.SET_SCHEDULE_PASSENGERS_LIST:
                let list;
                if (!state?.passengersList ) {
                     list = produce([], draftState => {
                        draftState.push(action.payload);
                    })
                }else {
                    const checkExistedItem = state.passengersList.filter(passenger => passenger.staff_no === action?.payload.staff_no)

                    if (checkExistedItem.length === 0){
                        list = produce(state.passengersList, draftState => {
                            draftState.push(action.payload);
                        })
                    }else {
                        list = state.passengersList;
                    }
                }

                const newArr = list.map((p, index) => {
                    if (  index > 0 ) {
                        
                        if (p.driver === list[index - 1].driver){
                          p = {
                            ...p,
                            driverShare: true
                          }
                        }
                    }else {
                        if (p.driver == list[index].driver){
                            p = {
                                ...p,
                                driverShare: true
                            }
                        }else{
                            p = {
                                ...p,
                                driverShare: false
                            }
                        }
                        
                    }
                    console.log(p);
                    return p;
                });

                return {
                    ...state,
                    passengersList: newArr
                } 

            case ActionTypes.CHANGE_DATETIME_TO_PAST: 
                const products = state.passengersList.map(p => {
                    
                    
                    if (p.pid) {
                        var subtime =state?.formData?.flightType ? state?.formData?.flightType : 3.0
                        let myStringParts = subtime.split('.')
                        let hourDelta = myStringParts[0]
                        let minuteDelta =myStringParts[1]
                        p = {
                            ...p,
                            pickDateTime : moment(action.payload ? action.payload : p.pickDateTime).subtract({ hours: hourDelta, minutes: minuteDelta}).format()
                        }
                        return p;
                    }else {
                        console.log('subtime2',action.payload);
                        p = {
                            ...p,
                            flightTime: moment(new Date(action.payload)).format()
                        }
                        return p;
                    }
                })
                // const products = produce(state.passengersList, draftState => {
                //     moment(draftState[0].pickDateTime).subtract(3, 'h');
                // })

                return {
                    ...state,
                    passengersList: products
                }
            
            default:
               return state;
          }
    }catch (e) {
        console.log(e);
    }
};

export default ScheduleReducer;
