import React, {useState, useEffect} from 'react';
import PrivateTwoColumns from 'modules/layouts/PrivateTwoColumns';
import { connect } from 'react-redux';
import { Content } from 'adminlte';
import {
  Card,
  CardHeader,
  CardBody,
  Button
} from 'reactstrap';
import UserForm from 'User/compnents/UserForm';
import DataTableListing from 'pages/dataTable/DataTableListing';
import  {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import * as genericAction from 'generic/actions';
import DeleteModal from 'pages/confirmation/DeleteModal';
import PageLoading from 'components/page-loading/PageLoading';
import CashModal from '../compnents/CashModal';
import BulkModal from '../compnents/BulkModal';
import styled from 'styled-components';
import { orderBy } from 'lodash';


// Filtering input box 

const TextField = styled.input`
	height: 32px;
	width: 200px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;

	&:hover {
		cursor: pointer;
	}
`;

const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 34px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear,setFilterText }) => (
	<>
		<TextField
			id="search"
			type="text"
      autoComplete="nope"
			placeholder="Filter By Name"
			aria-label="Search Input"
			value={filterText}
      onChange={event => {setFilterText(event.target.value)}}
      onKeyPress={event => {
        if (event.key === 'Enter') {
          onFilter()
        }
      }}
		//	onChange={onFilter}
		/>
		<ClearButton type="button" onClick={onClear}>
			X
		</ClearButton>
	</>
);

//it's end here 

function User({dispatch}) {
  const [appLoadingState, updateAppLoading] = useState(false);
  const [thumbPath, setThumbPath] = useState([]);

  // for server side pagination
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  // for server side filtering
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  //server side sorting
  const [loading, setLoading] = useState(false);

    
  const [temporaryData, setTemporaryData] = useState({});
  const [locationCodeProps, setlocationCodeProps] = useState([]);
  const [cashData, setCashData] = useState();
  const [mode, setMode] = useState({
    edit: false,
  });
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [filePath, setFilePath] = useState([]);

  const [showCashModal, setCashModal] = useState(false);
  const toggleCash = () => setCashModal(!showCashModal);

  const [showBulkModal, setBulkModal] = useState(false);
  const toggleBulk = () => setBulkModal(!showBulkModal);

  const [deleteModal, setDeleteModal] = useState(false);
  const deleteToggle = () => {
    setDeleteModal(!deleteModal)
  };

  const [uniqueId, setUniqueId] = useState(1);
  const [pickAddress, setPickAddress] = useState("");
  const [pickCoordinates, setPickCoordinates] = useState({
      lat: null,
      lng: null
  })

  const [locAddress, setLocAddress] = useState("");
  const [locCoordinates, setLocCoordinates] = useState({
      lat: null,
      lng: null
  });

  const [data, setData] = useState([]);

  useEffect(() => {
    if (data.length === 0) {
      getCustomersAsync(1);
      getLocationsAsync();
    }
  }, [])

  
  const getLocationsAsync = () => {
    updateAppLoading(true);
    dispatch(genericAction.callGenericGetterAsync('/admin/locations', (res) => {
      if (res) {
        setlocationCodeProps(res?.data);
        updateAppLoading(false);
      }
    }));
  }

  const getCustomersAsync =  (page,search='') => {
    updateAppLoading(true);
    dispatch(genericAction.callGenericGetterAsync(`/admin/users?page=${page}&per_page=${perPage}&delay=1&search=${search}`, (res) => {
      if (res) {
        setData(res?.data);
        setTotalRows(res?.total);
        updateAppLoading(false);
      }
    }));

  }
  
  const handlePageChange = page => {
		getCustomersAsync(page);
	};

  const handlePerRowsChange =  (newPerPage, page) => {
		updateAppLoading(true);

		dispatch(genericAction.callGenericGetterAsync(`/admin/users?page=${page}&per_page=${perPage}&delay=1`, (res) => {
      if (res) {
        setData(res?.data);
        setPerPage(newPerPage);
        updateAppLoading(false);
      }
    }));
	};

/* sorting on the table */

  const handleSort =  (column, sortDirection , page) => {
		// simulate server sort
    setLoading(true);

		// instead of setTimeout this is where you would handle your API call.
      dispatch(genericAction.callGenericGetterAsync(`/admin/users?sort=${column.sortField}&order=${sortDirection}`, (res) => {
			setData(orderBy(res?.data ,column?.sortField , sortDirection ));
      setLoading(false);

      }))
	};
/* sorting sections end here */  

/* filtering the record from the table */ 

  const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText();
			}
		};
   
		return (
			<FilterComponent  setFilterText={setFilterText } filterText={filterText} onFilter={(e) =>{ getCustomersAsync(1,filterText);  } } onClear={handleClear}  />
		);

	}, [filterText, resetPaginationToggle]);
/*And it's end here  */


  const getCashDataAsync = (id) => {
    updateAppLoading(true);
    dispatch(genericAction.callGenericGetterAsync(`/wallet/${id}`, (res) => {
      if (res) {
        setCashData(res?.data);
        updateAppLoading(false);
      }
    }));
  }

  const handleLocSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setLocAddress(value)
    setLocCoordinates(latLng)
  }

  const handlePickSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setPickAddress(value)
    setPickCoordinates(latLng)
  }

  const deleteCustomerHandler = () => {
      if (uniqueId) {
        dispatch(genericAction.callGenericAsync({}, `/admin/user-save/${uniqueId}`, 'delete', (res) => {
          if(res) {
            getCustomersAsync();
          }
        }))
      }else {
        alert("Data not found.");
      }
  }

  const submitBulkHandler = ({payload, closeModal, setSubmitting, resetForm }) => {

    if (filePath.length > 0) {
      payload.filePath = filePath;
    }
      dispatch(genericAction.callGenericAsync(payload, '/users/bulk-customers/', 'post', (res) => {
        setSubmitting(false);
        if(res) {
          // toggle();
          // resetForm();
          // getAsync();
          // setTemporaryData({})
        }
      }))
  }

  const submitCustomerHandler = ({payload, closeModal, setSubmitting, resetForm }) => {
    payload.address = pickAddress;
    payload.address_tmp = pickAddress;
    payload.lat = pickCoordinates?.lat;
    payload.long = pickCoordinates?.lng;
    
    if (mode?.edit) {
      dispatch(genericAction.callGenericAsync(payload, `/admin/user-save/${payload.id}`, 'put', (res) => {
        if(res) {
          toggle();
          setSubmitting(false);
          resetForm();
          getCustomersAsync();
          setTemporaryData({})
        }
      }))
    } else {
      dispatch(genericAction.callGenericAsync(payload, '/admin/user-save', 'post', (res) => {
        if(res.status == 1) {
          toggle();
          setSubmitting(false);
          resetForm();
          getCustomersAsync();
          setTemporaryData({})
        }else{
          setSubmitting(false);
          //getCustomersAsync();
         // setTemporaryData({})
        }
      }))
    }
  }

  const jobAreaProps = [
    {title: "Cabin", value: 'Cabin'},
    // {title: "Health", value: 2},
    // {title: "Business", value: 3}
  ];

  const designationProps = [
    {title: "SP", value: "SP"},
    {title: "AH", value: "AH"},
    {title: "FS", value: "FS"},
    {title: "BO", value: "BO"},
    {title: "AE", value: "AE"},
    {title: "FO", value: "FO"}
  ];
  // const locationCodeProps = [
  //   {value: 1, title: "CD"},
  //   {value: 2, title: "MS"},
  //   {value: 3, title: "G-Sect"},
  //   {value: 4, title: "F-Sect"},
  //   {value: 5, title: "E-Sect"}
  // ];

  const statusProps = [
    {title: "Active", value: 1},
    {title: "InActive", value: 0}
  ];

  const columns = [
    {
      name: "P No",
      selector: "staff_no",
      maxWidth: "20px",
      sortable: true,
      sortField: 'staff_no'
  },
    {
        name: "Name",
        selector: "fullname",
        maxWidth: "160px",
        sortable: true,
        sortField: 'fullname'
    },
    {
        name: "phone",
        selector: "phone",
        maxWidth: "120px",
        sortable: true,
        sortField: 'phone'
    },
    {
      name: "cell",
      selector: "cell",
      maxWidth: "130px",
      sortable: true,
      // sortField: 'phone'
  },
   
    {
      name: "Permanent Address",
      maxWidth: "445px",
      selector: "location",
      sortable: true,
      sortField: "location"
    },
    // {
    //     name: "Designation",
    //     selector: "designation",
    //     maxWidth: "195px",
    //     sortable: true,
    //     sortField: 'designation'
    // },
    // {
    //     name: "Status",
    //     selector: (row) => {
    //       return row?.status ? "true" : "false";
    //     },
    //     maxWidth: "15px",
    //     sortable: true,
    //     sortField: 'status'
    // },
    {
      name: "",
      maxWidth: "10px",
      minWidth: "10px",
        cell: (data) => {
          return (<i className="text-info fas fa-edit"  onClick={() => {
            setTemporaryData(data);
            setPickAddress(data?.location)
            setPickCoordinates({
              lat: data?.lat,
              lng: data?.long
            })
            setLocAddress(data?.location_tmp)
            setMode({
              edit: true
            });
            toggle();
          }}/>)
      },
    },
    {
      name: "",
      maxWidth: "10px",
      minWidth: "10px",
        cell: (data) => {
          return (<i className="text-danger fas fa-trash-alt" onClick={() => {
            setUniqueId(data?._id);
            deleteToggle();
          }}/>)
      },
    },
    {
      name: "",
      maxWidth: "10px",
      minWidth: "10px",
        cell: (data) => {
          return (<i className="text-secondary fas fa-eye" onClick={() => {
            toggleCash();
            getCashDataAsync(data?._id);
          }}/>)
      },
    }
  ];

  return (
    <>
    {appLoadingState ? <PageLoading /> :
      <PrivateTwoColumns pageTitle="Customers">
        <Content>
            {/* <Content.Header>
              <Content.Title>Create Customer</Content.Title>
            </Content.Header> */}
            <Content.Wrapper>
              <Card className="card-info card-outline">
                <CardHeader>
                  <div>
                    <h3 class="card-title">Customers Information</h3>
                  </div>
                  <div className="text-right">
                    <Button color="info" size="sm" onClick={() => {
                      toggle()
                      setMode({
                        edit: false
                      });
                      setTemporaryData({});
                    }}>+ Add New Customer</Button>

                    {/* <Button color="primary" className='ml-2' size="sm" onClick={() => {
                      toggleBulk()
                      
                      setTemporaryData({});
                    }}>+ Bulk Customer</Button> */}

                  </div>
                </CardHeader>
                <CardBody>
                  <UserForm 
                    modal={modal} 
                    toggle={toggle}
                    pickAddress={pickAddress}
                    setPickAddress={setPickAddress}
                    handlePickSelect={handlePickSelect}
                    pickCoordinates={pickCoordinates}
                    setPickCoordinates={setPickCoordinates}
                    handleLocSelect={handleLocSelect}
                    locCoordinates={locCoordinates}
                    setLocCoordinates={setLocCoordinates}
                    locAddress={locAddress}
                    setLocAddress={setLocAddress}
                    submitCustomerHandler={submitCustomerHandler}
                    designationProps={designationProps}
                    jobAreaProps={jobAreaProps}
                    statusProps={statusProps}
                    locationCodeProps={locationCodeProps}
                    temporaryData={temporaryData}
                    mode={mode}
                  />
                  <DataTableListing
                    title="User Listing"
                    columns={columns} 
                    data={data} 
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    sortServer
                    onSort={handleSort}
                    paginationResetDefaultPage={resetPaginationToggle}
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    progressPending={loading}
                    />
                </CardBody>
              </Card>
            </Content.Wrapper>
            <DeleteModal modal={deleteModal} toggle={deleteToggle} deleteCustomer={deleteCustomerHandler}/>
            <CashModal modal={showCashModal} toggle={toggleCash} data={cashData} />
            <BulkModal modal={showBulkModal} toggle={toggleBulk} setFilePath={setFilePath} thumbPath={thumbPath} submitBulkHandler={submitBulkHandler} />
        </Content>
      </PrivateTwoColumns>
    }
    </>
  );
}


const mapStateToProps = ({ auth }) => {
  return {
    ...auth
  }
}
export default connect(mapStateToProps)(User);