import React, {useState, useEffect} from 'react';
import PrivateTwoColumns from 'modules/layouts/PrivateTwoColumns';
import { connect } from 'react-redux';
import { Content } from 'adminlte';
import {
  Card,
  CardHeader,
  CardBody,
  Button
} from 'reactstrap';
import DriverForm from 'driver/compnents/DriverForm';
import DataTableListing from 'pages/dataTable/DataTableListing';
import  {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import * as genericAction from 'generic/actions';
import DeleteModal from 'pages/confirmation/DeleteModal';
import PageLoading from 'components/page-loading/PageLoading';
import styled from 'styled-components';
import { orderBy } from 'lodash';



// Filtering input box 

const TextField = styled.input`
	height: 32px;
	width: 200px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;

	&:hover {
		cursor: pointer;
	}
`;

const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 34px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear,setFilterText }) => (
	<>
		<TextField
			id="search"
			type="text"
      autoComplete="nope"
			placeholder="Filter By Name"
			aria-label="Search Input"
			value={filterText}
      onChange={event => {setFilterText(event.target.value)}}
      onKeyPress={event => {
        if (event.key === 'Enter') {
          onFilter()
        }
      }}
		//	onChange={onFilter}
		/>
		<ClearButton type="button" onClick={onClear}>
			X
		</ClearButton>
	</>
);

//it's end here 



function Driver({dispatch}) {
  const [appLoadingState, updateAppLoading] = useState(false);
  const [temporaryData, setTemporaryData] = useState({});
  const [locationCodeProps, setlocationCodeProps] = useState([]);

   // for server side pagination
   const [totalRows, setTotalRows] = useState(0);
   const [perPage, setPerPage] = useState(10);
   // for server side filtering
   const [filterText, setFilterText] = useState('');
   const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
   //server side sorting
   const [loading, setLoading] = useState(false);

  const [thumbPath, setThumbPath] = useState([]);
  const [filePath, setFilePath] = useState([]);
  const [mode, setMode] = useState({
    edit: false,
  });
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [deleteModal, setDeleteModal] = useState(false);
  const deleteToggle = () => {
    setDeleteModal(!deleteModal)
  };

  const [uniqueId, setUniqueId] = useState(1);
  const [pickAddress, setPickAddress] = useState("");
  const [pickCoordinates, setPickCoordinates] = useState({
      lat: null,
      lng: null
  })

  const [locAddress, setLocAddress] = useState("");
  const [locCoordinates, setLocCoordinates] = useState({
      lat: null,
      lng: null
  });

  const [data, setData] = useState([]);

  useEffect(() => {
    if (data.length === 0) {
      getAsync(1);
      getLocationsAsync();
    }
  }, [])

  const getAsync = (page,search='') => {
    updateAppLoading(true);
    dispatch(genericAction.callGenericGetterAsync(`/driver/?page=${page}&search=${search}&per_page=${perPage}&delay=1`, (res) => {
      if (res) {
        setData(res?.data);
        setTotalRows(res?.total);
        updateAppLoading(false);
      }
    }));
  }

  const handlePageChange = page => {
		getAsync(page);
	};

  const handlePerRowsChange =  (newPerPage, page) => {
		updateAppLoading(true);
   
		dispatch(genericAction.callGenericGetterAsync(`/driver/?page=${page}&per_page=${newPerPage}&delay=1`, (res) => {
      if (res) {
        console.log('jdnewPerPage',newPerPage);
        setData(res?.data);
        setPerPage(newPerPage);
        updateAppLoading(false);
      }
    }));
	};

  /* sorting on the table */

  const handleSort =  (column, sortDirection ) => {
		// simulate server sort
    setLoading(true);
		// instead of setTimeout this is where you would handle your API call.
      dispatch(genericAction.callGenericGetterAsync(`/driver/?sort=${column.sortField}&order=${sortDirection}`, (res) => {
			setData(orderBy(res?.data ,column?.sortField , sortDirection ));
      setLoading(false);

      }))
	};
/* sorting sections end here */  

/* filtering the record from the table */ 

const subHeaderComponentMemo = React.useMemo(() => {
  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText();
    }
  };
 
  return (
    <FilterComponent  setFilterText={setFilterText } filterText={filterText} onFilter={(e) =>{ getAsync(1,filterText);  } } onClear={handleClear}  />
  );

}, [filterText, resetPaginationToggle]);
/*And it's end here  */



  const getLocationsAsync = () => {
    updateAppLoading(true);
    dispatch(genericAction.callGenericGetterAsync('/admin/locations', (res) => {
      if (res) {
        setlocationCodeProps(res?.data);
        updateAppLoading(false);
      }
    }));
  }
  
  const handleLocSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setLocAddress(value)
    setLocCoordinates(latLng)
  }

  const handlePickSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setPickAddress(value)
    setPickCoordinates(latLng)
  }

  const deleteHandler = () => {
      if (uniqueId) {
        dispatch(genericAction.callGenericAsync({}, `/driver/${uniqueId}`, 'delete', (res) => {
          if(res) {
            getAsync();
          }
        }))
      }else {
        alert("Data not found.");
      }
  }

  const submitHandler = ({payload, closeModal, setSubmitting, resetForm }) => {
    payload.address = locAddress;
    payload.address_tmp = pickAddress;
    payload.lat = pickCoordinates?.lat;
    payload.long = pickCoordinates?.lng;
    if (filePath.length > 0) {
      payload.image = filePath;
      payload.thumbImage = thumbPath;
    }
    
    if (mode?.edit) {
      dispatch(genericAction.callGenericAsync(payload, `/driver/${payload.id}`, 'put', (res) => {
        setSubmitting(false);
        if(res?.status) {
          toggle();
          resetForm();
          getAsync();
          setTemporaryData({})
        }
      }))
    } else {
      dispatch(genericAction.callGenericAsync(payload, '/driver/', 'post', (res) => {
        setSubmitting(false);
        if(res?.status == 1) {
          toggle();
          resetForm();
          getAsync();
          setTemporaryData({})
        }else{
          setSubmitting(false);
        }
      }))
    }
  }

  // const assignedPassengerProps = [
  //   {title: "Jawad Ali", value: 1},
  //   {title: "Salman Bukhari", value: 2},
  // ];
  // const locationCodeProps = [
  //   {value: "1", title: "CD"},
  //   {value: "2", title: "MS"},
  //   {value: "3", title: "G-Sect"},
  //   {value: "4", title: "F-Sect"},
  //   {value: "5", title: "E-Sect"}
  // ];
  const statusProps = [
    {title: "Active", value: 1},
    {title: "InActive", value: 0}
  ];

  const columns = [
    {
        name: "Name",
        selector: "fullname",
        sortable: true,
        sortField: 'fullname'

    },
    {
        name: "Vehicle Model",
        selector: "vehicle_model",
        sortable: true,
        sortField: 'vehicle_model'

    },
    {
      name: "Cell",
      selector: "cell",
      sortable: true,
      sortField: 'cell'

  },
    {
        name: "Vehicle No.",
        selector: "vehicle_no",
        sortable: true,
        sortField: 'vehicle_no'

    },
    {
        name: "Vehicle Capacity",
        selector: (row) => {
          return row?.vehicle_capacity;
        },
        sortable: true,
        sortField: 'vehicle_capacity'

    },
    {
      name: "Contact No.",
      selector: "cell",
      sortable: true,
      sortField: 'cell'
    },
    {
      name: "Images",
      cell: ({ image, thumbImage }) => {
          return (<div>
              {thumbImage.map(t => <img src={ t} alt="Reviews Thumbs" width="100" height="100" className="img-thumbnail rounded" />)}
          </div>)
      }
    },
    {
      name: "Action",
        cell: (data) => {
          return (<i className="text-info fas fa-edit"  onClick={() => {
            setTemporaryData(data);
            setPickAddress(data?.location)
            setPickCoordinates({
              lat: data?.lat,
              lng: data?.long
            })
            setLocAddress(data?.location)

            setMode({
              edit: true
            });
            toggle();
          }}/>)
      },
      maxWidth: "5%",
    },
    {
      name: "",
        cell: (data) => {
          return (<i className="text-danger fas fa-trash-alt" onClick={() => {
            setUniqueId(data?._id);
            deleteToggle();
          }}/>)
      },
      maxWidth: "5%",
    }
  ];

  const filteredItems = data.filter(
		item => item.fullname && item.fullname.toLowerCase().includes(filterText.toLowerCase()),
	);

  return (
    <>
    {appLoadingState ? <PageLoading /> :
      <PrivateTwoColumns pageTitle="Driver">
        <Content>
            <Content.Wrapper>
              <Card className="card-info card-outline">
                <CardHeader>
                  <div>
                    <h3 class="card-title">Drivers Information</h3>
                  </div>
                  <div className="text-right">
                    <Button color="info" size="sm" onClick={() => {
                      toggle()
                      setMode({
                        edit: false
                      });
                      setTemporaryData({});
                    }}>+ Add new Driver</Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <DriverForm 
                    modal={modal} 
                    toggle={toggle}
                    pickAddress={pickAddress}
                    setPickAddress={setPickAddress}
                    handlePickSelect={handlePickSelect}
                    pickCoordinates={pickCoordinates}
                    setPickCoordinates={setPickCoordinates}
                    handleLocSelect={handleLocSelect}
                    locCoordinates={locCoordinates}
                    setLocCoordinates={setLocCoordinates}
                    locAddress={locAddress}
                    setLocAddress={setLocAddress}
                    submitHandler={submitHandler}
                    // assignedPassengerProps={assignedPassengerProps}
                    statusProps={statusProps}
                    locationCodeProps={locationCodeProps}
                    temporaryData={temporaryData}
                    mode={mode}
                    thumbPath={thumbPath}
                    setFilePath={setFilePath}
                    setThumbPath={setThumbPath}
                  />
                  <DataTableListing 
                  columns={columns} 
                  data={data} 
                  title="Driver Listing"
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  sortServer
                  progressPending={loading}
                  onSort={handleSort}
                  paginationResetDefaultPage={resetPaginationToggle}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  />
                </CardBody>
              </Card>
            </Content.Wrapper>
            <DeleteModal modal={deleteModal} toggle={deleteToggle} deleteCustomer={deleteHandler}/>
        </Content>
      </PrivateTwoColumns>
    }
    </>
  );
}


const mapStateToProps = ({ auth }) => {
  return {
    ...auth
  }
}
export default connect(mapStateToProps)(Driver);