import Wrapper from "./Content/Wrapper";
import TwoColumns from "./Layout/TwoColumns"


const Layout = () => {

}

Layout.Wrapper = Wrapper;
Layout.TwoColumns = TwoColumns;

export default Layout;