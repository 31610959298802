import React, {useState} from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, FormText, Row, Col } from 'reactstrap';
import moment from 'moment';
import { DateTime } from 'luxon';
import * as Yup from 'yup';
import { Field, ErrorMessage, withFormik, Form } from 'formik';
import AdminLTE from 'adminlte';

const InnerForm = ({
    errors,
    isSubmitting,
    handleSubmit,
    handleChange,
    modal,
    toggle,
    className,
    driversProps,
    statusProps,
    mode,
    temporaryData,
    setThumbPath,
    setFilePath,
    thumbPath,
    
}) => {

  
    return (
        <Modal isOpen={modal} toggle={toggle} className={className} size="sm">
            <ModalHeader toggle={toggle}>Schedule</ModalHeader>
            <Form onSubmit={handleSubmit} className="addressForm">
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="flight">Status</Label>
                            <select
                                    name="driver"
                                    id="driver"
                                    size="md"
                                    onChange={handleChange}
                                    className={'form-control form-control-md'}>
                                    <option value="">Please select</option>
                                    {driversProps?.map(driver => <option value={driver._id}>{driver.fullname}</option>)}
                                </select>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                    <div className="text-right">
                        <Input type="hidden" name="id" id="id" />
                        <AdminLTE.Button.Spinner 
                            block color="primary" 
                            isSubmitting={isSubmitting} 
                            disabled={isSubmitting} 
                            text={ "Assign Driver"} 
                            type="submit" 
                            size="sm">
                            Saving Driver...
                    </AdminLTE.Button.Spinner>
                    </div>
                </ModalFooter>

            </Form>
            
        </Modal>
    )
}


const DriverAssignModal = withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ temporaryData, locCoordinates, pickCoordinates }) => {
        return {
            trip_id: temporaryData && temporaryData?._id,
            driver: temporaryData && temporaryData?.driver,
            id: temporaryData && temporaryData?.passenger?._id
        }
    },
    validationSchema: Yup.object().shape({
        driver: Yup.string().required("driver is required")
    }),
    handleSubmit: (values, { props: { submitHandler, closeModal }, setSubmitting, resetForm }) => {
        setSubmitting(true);
        submitHandler({payload: values, closeModal, setSubmitting, resetForm});
    },
})(InnerForm);


export default DriverAssignModal


//export default DriverAssignModal
