import React, { Component, useState, createRef } from 'react';
import Autocomplete from 'react-autocomplete'
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import CanUseDOM from 'can-use-dom';
// import { colourOptions } from '../data';

const AsyncPassengerSelect = ({ setFormData, resetValidate, validateForm, formData,passengersList }) => {
    const [items, setItems] = useState([]);
    
    let className = "form-control form-control-sm";
    if (validateForm?.passengerNo) {
        className = "form-control form-control-sm is-invalid";
    }

    return (
        <Autocomplete
            inputProps={{ className, type: 'number' }}
            getItemValue={(item) => item?.staff_no}
            items={items}
            renderItem={(item, isHighlighted) => {
                return (
                    <div className="name-autocomplete" style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                        {item?.fullname}
                    </div>
                );
            }}
            value={formData?.passengerNo}
            onChange={(e) => {
                (async () => {
                    try {
                        
                        setFormData(e?.target?.value, "passengerNo")
                        // setValue(e?.target?.value);
                        resetValidate();
                        let res = await axios.get(`/users/${e?.target?.value}`, { baseURL: process.env.REACT_APP_API_BASE_URL, headers: { "Authorization": "bearer " + localStorage.getItem('token') } });
                        if (res?.data?.status) {
                            setItems(res?.data?.data);
                        }
                    } catch(e) {
                        console.log(e);
                    }
                })();
            }}
            onSelect={(val) =>{ 
              //  console.log(passengersList);
                val && setFormData(val, "passengerNo")
            }}
        />
    );

    
}

export default AsyncPassengerSelect;
