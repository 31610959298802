import React from 'react'
import {Helmet} from "react-helmet";

function Header({pageTitle, companyName}) {
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{pageTitle} | {companyName}</title>
        </Helmet>
        
        </>
    )
}

Header.defaultProps = {
    pageTitle: "Dashboard",
    companyName: "Khizar Admin"
}

export default Header
