import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux';
import {useHistory} from 'react-router-dom';
import Header from 'modules/main/header/Header';
import HelmetHeader from 'pages/Header';
import MenuSidebar from 'modules/main/menu-sidebar/MenuSidebar';
import * as genericAction from 'generic/actions';
import * as ActionTypes from '../../store/actions';

function PrivateTwoColumns({children, pageTitle, dispatch}) {
    
    const history = useHistory();
    const [menusidebarState, updateMenusidebarState] = useState({
        isMenuSidebarCollapsed: false
    });

    const toggleMenuSidebar = () => {
        updateMenusidebarState({
            isMenuSidebarCollapsed: !menusidebarState.isMenuSidebarCollapsed
        });
    };
    document.getElementById('root').classList.remove('register-page');
    document.getElementById('root').classList.remove('login-page');
    document.getElementById('root').classList.remove('hold-transition');

    document.getElementById('root').className += ' sidebar-mini';

    if (menusidebarState.isMenuSidebarCollapsed) {
        document.getElementById('root').classList.add('sidebar-collapse');
        document.getElementById('root').classList.remove('sidebar-open');
    } else {
        document.getElementById('root').classList.add('sidebar-open');
        document.getElementById('root').classList.remove('sidebar-collapse');
    }

    useEffect(() => {
        dispatch(genericAction.callGenericGetterAsync('/auth/me', (res) => {
            if (!res?.auth){
                localStorage.clear();
                history.push('/login')
            }else{
                // localStorage.setItem('token', res?.token);
                // localStorage.setItem('email', res?.email);
                dispatch({type: ActionTypes.VERIFY_USER, data: res});
            }
        }))
    }, [])

    return (
        <div>
            <HelmetHeader pageTitle={pageTitle} />
            <Header toggleMenuSidebar={toggleMenuSidebar} />
            <MenuSidebar />
            {children}
            <div
                id="sidebar-overlay"
                role="presentation"
                onClick={toggleMenuSidebar}
                onKeyDown={() => {}}
            />
        </div>
    )
}

PrivateTwoColumns.defaultProps = {
    pageTitle: "Dashboard",
}


const mapStateToProps = ({ auth }) => {
    return {
      ...auth
    }
}
export default connect(mapStateToProps)(PrivateTwoColumns);