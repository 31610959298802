import React, {useState, useEffect} from 'react'
import PrivateTwoColumns from 'modules/layouts/PrivateTwoColumns';
import { connect } from 'react-redux';
import { Content } from 'adminlte';
import {
  Card,
  CardHeader,
  CardBody,
  Button 
} from 'reactstrap';
import DataTableListing from 'pages/dataTable/DataTableListing';
import * as genericAction from 'generic/actions';
import PageLoading from 'components/page-loading/PageLoading';
import ScheduleModal from '@app/schedule/components/ScheduleModal';
import  {
    geocodeByAddress,
    getLatLng
} from 'react-places-autocomplete';
import produce from 'immer';
import * as actions from '../../actions';
import DeleteModal from 'pages/confirmation/DeleteModal';
import { DateTime } from 'luxon';



function Completed({dispatch, formData, passengersList}) {
    const [appLoadingState, updateAppLoading] = useState(false);
    const [locationTextToggle, setLocationTextToggle] = useState('Drop Location');
    const [temporaryData, setTemporaryData] = useState({});
    const [mode, setMode] = useState({
      edit: false,
    });
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [dropAddress, setDropAddress] = useState("");
    const [dropCoordinates, setDropCoordinates] = useState({
        lat: null,
        lng: null
    });
    
    const [data, setData] = useState([]);

    const [uniqueId, setUniqueId] = useState(1);

    const [deleteModal, setDeleteModal] = useState(false);
    const deleteToggle = () => {
        setDeleteModal(!deleteModal)
    };
    
    const [validateForm, setValidateForm] = useState({
      flightNumber: false,
      date: false,
      status: false,
      passengerNo: false,
    });


    const getScheduleAsync = () => {
        
        dispatch(genericAction.callGenericGetterAsync(`/admin/schedule/?status=completed`, (res) => {
            if (res?.data)
                setData(res?.data);
        }))
    }

    const validate = (validator) => {
      setValidateForm((prev) => {
        return {
          ...prev,
          [validator]: true
        }
      })
    }

    const loadPassengersAsync = () => {
        const {
          passengerNo,
          flightNumber,
          pickDateTime,
          rideStatus,
          dropLocation
        } = formData;
        
        if (!flightNumber) {
          validate('flightNumber');
        }

        if (pickDateTime ==''){
          validate('pickDateTime');
        }

        if (!rideStatus){
          validate('rideStatus');
        }

        if (!dropLocation){
          validate('dropLocation');
        }

        if (!passengerNo){
          validate('passengerNo');
        }

        if (flightNumber && pickDateTime && passengerNo && rideStatus && dropLocation) {
          
          dispatch(genericAction.callGenericGetterAsync(`/users/loadUser/${passengerNo}`, (res) => {
              if (res?.data)
                 var udata = res?.data
                udata.flightTime = pickDateTime
                dispatch(actions.setSchedulePassengersList(udata));
          }))
        }
    }

    const setFormData = (value, name) => {
        const producedData = produce(formData, drafState => {
            drafState[name] = value;
        })
        dispatch( actions.setScheduleFormData(producedData) );
    }

    useEffect(() => {
        getScheduleAsync();
    },[]);


    const handleDropSelect = async (value) => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        setDropAddress( value );
        setDropCoordinates(latLng);
        setFormData(value, 'dropLocation');
    }

    const removePassenger = (index) => {
      const newArr = passengersList?.map(passenger => passenger);
      newArr?.splice(index, 1);
      dispatch(actions.setScheduleUsers(newArr));
    }

    const saveScheduleHandler = () => {[``]
        const payload =  {
            pNo: formData?.passengerNo,
            // pickLocation: data?.location_tmp,
            dropLocation: dropAddress,
            flightNumber: formData?.flightNumber,
            picklat: data?.lat,
            pickLong: data?.lng,
            droplat: dropCoordinates?.lat,
            droplong: dropCoordinates?.lng,
            status: formData?.rideStatus,
            pickDateTime: formData?.pickDateTime,
            passenger: passengersList,
        }
        
      if (mode?.edit) {
          

          let passArr = [];
          passengersList.forEach(passenger => {
            if (passenger.pid){
              passArr.push(passenger)
            }else {
              passArr.push({
                pid: passenger
              });
            }
          })

          const editPayload =  {
            pNo: formData?.passengerNo,
            // pickLocation: data?.location_tmp,
            dropLocation: dropAddress,
            flightNumber: formData?.flightNumber,
            picklat: data?.lat,
            pickLong: data?.lng,
            droplat: dropCoordinates?.lat,
            droplong: dropCoordinates?.lng,
            status: formData?.rideStatus,
            pickDateTime: formData?.pickDateTime,
            passenger: passArr,
          }

          dispatch(genericAction.callGenericAsync(editPayload, `/schedule/admin-schedule-update/${formData.id}`, 'put', (res) => {
              if(res) {
                passArr = [];
              }
          }))
        }else{
          dispatch(genericAction.callGenericAsync(payload, '/schedule', 'post', (res) => {
            if(res) {
              // console.log(res);
            }
        }))
        }
    }


    const getScheduleByIdAsync = (id) => {
      dispatch(genericAction.callGenericAsync({}, `/schedule/edit/${id}`, 'get', (res) => {
        if(res) {
          dispatch(actions.setScheduleUsers(res?.data?.passenger));
          setTemporaryData(res?.data);
        }
      }))
    }

    const deleteCustomerHandler = () => {
        if (uniqueId) {
          dispatch(genericAction.callGenericAsync({}, `/schedule/admin/${uniqueId}`, 'delete', (res) => {
            if(res) {
              getScheduleAsync();
            }
          }))
        }else {
          alert("Data not found.");
        }
    }

     
    const columns = [
        {
            name: "Flight Number",
            selector: "flightNumber",
            sortable: true
        },
        {
            name: "Drop Location",
            selector: "dropLocation",
            sortable: true,
        },
        {
            name: "No. of Passengers",
            selector: (row) => {
                // console.log(row);
                return row?.passenger.length;
            },
            sortable: true
        },
        {
            name: "Ride Status",
            selector: (row) => {
              return row?.status;
            },
            sortable: true
        },
        {
          name: "Action",
         
          name: "",
            cell: (data) => {
              return (<i className="text-danger fas fa-trash-alt" onClick={() => {
                setUniqueId(data?._id);
                deleteToggle();
              }}/>)
          },
          maxWidth: "5%",
        }
    ];


    return (
        <>
        {appLoadingState ? <PageLoading /> :
        <PrivateTwoColumns pageTitle="Schedule">
            <Content>
                {/* <Content.Header>
                <Content.Title>Create Schedule</Content.Title>
                </Content.Header> */}
                <Content.Wrapper>
                <Card className="card-info card-outline">
                    <CardHeader>
                    <div>
                        <h3 class="card-title">Completed Information</h3>
                    </div>
                    
                    </CardHeader>
                    <CardBody>
                    <DataTableListing 
                      columns={columns} 
                      data={data}  
                      title="Schedule Listing"
                      subHeader
                      // subHeaderComponent={subHeaderComponentMemo}
                    />
                    </CardBody>
                </Card>
                </Content.Wrapper>
                <ScheduleModal 
                    modal={modal} 
                    toggle={toggle} 
                    handleDropSelect={handleDropSelect} 
                    dropAddress={dropAddress}
                    setDropAddress={setDropAddress}
                    setFormData={setFormData}
                    loadPassengers={loadPassengersAsync}
                    passengersList={passengersList?.length > 0 ? passengersList : []}
                    saveScheduleHandler={saveScheduleHandler}
                    temporaryData={temporaryData}
                    formData={formData}
                    mode={mode}
                    removePassenger={removePassenger}
                    validateForm={validateForm}
                    setValidateForm={setValidateForm}
                    locationTextToggle={locationTextToggle}
                    setLocationTextToggle={setLocationTextToggle}
                />
            </Content>
            <DeleteModal modal={deleteModal} toggle={deleteToggle} deleteCustomer={deleteCustomerHandler}/>
        </PrivateTwoColumns>
        }
        </>
    )
}

const mapStateToProps = ({ auth, schedule }) => {
    return {
      ...auth,
      ...schedule
    }
  }
  export default connect(mapStateToProps)(Completed);