import React from 'react'
import { Table, Button } from 'reactstrap';
import moment from 'moment';

function ScheduleTable({formData, data, removePassenger}) {
    return (
        <Table>
            <thead>
                <tr>
                    <th>Staff Code</th>
                    <th>Name</th>
                    <th>Designation</th>
                    <th>Driver</th>
                    <th>P-Time</th>
                    <th>Job Area</th>
                    <th>Address</th>
                    <th>Delete</th>
                </tr>
            </thead>
            <tbody>
                {data?.map((data, index) => {
                    let datetime;
                    if (data?.pick_at) {
                        datetime = moment(data?.pick_at).format('DD-MM-YYYY HH:mm');
                    }else {
                        console.log('subtime2',formData?.flightType);
                        var subtime =formData?.flightType ? formData?.flightType : 3.0
                        
                        let myStringParts = subtime.split('.')
                        let hourDelta = myStringParts[0]
                        let minuteDelta =myStringParts[1]

                        datetime = moment(data?.flightTime).subtract({ hours: hourDelta, minutes: minuteDelta}).format('DD-MM-YYYY HH:mm');
                    }
                    return (
                        <tr>
                            <td>{data?.driverShare} {data?.pid?.staff_no || data?.staff_no}</td>
                            <td>{data?.pid?.fullname || data?.fullname}</td>
                            <td>{data?.pid?.designation || data?.designation}</td>
                            <td>{data?.pid?.driver?.fullname || data?.driver_name}</td>
                            <td>{datetime}</td>
                            <td>{data?.pid?.job_area || data?.job_area}</td>
                            <td>{data?.pid?.location || data?.location}</td>
                            <td><i class="text-danger fas fa-trash-alt" style={{cursor: 'pointer'}} onClick={ () =>{ removePassenger(index,data?.pid?._id || data?._id) } }></i></td>
                        </tr>
                    );
                })}
            </tbody>
            </Table>
    )
}

export default ScheduleTable
