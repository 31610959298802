import React, {useState, useEffect} from 'react'
import PrivateTwoColumns from 'modules/layouts/PrivateTwoColumns';
import { connect } from 'react-redux';
import { Content } from 'adminlte';
import {
  Card,
  CardHeader,
  CardBody,
  Button
} from 'reactstrap';
import DataTableListing from 'pages/dataTable/DataTableListing';
import * as genericAction from 'generic/actions';
import PageLoading from 'components/page-loading/PageLoading';
import ScheduleModal from '@app/schedule/components/ScheduleModal';
import  {
    geocodeByAddress,
    getLatLng
} from 'react-places-autocomplete';
import produce from 'immer';
import * as actions from '../actions';
import DeleteModal from 'pages/confirmation/DeleteModal';
import { DateTime } from 'luxon';

function Schedule({dispatch, formData, passengersList}) {
    const [appLoadingState, updateAppLoading] = useState(false);
    const [temporaryData, setTemporaryData] = useState({});
    const [mode, setMode] = useState({
      edit: false,
    });
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [dropAddress, setDropAddress] = useState("");
    const [dropCoordinates, setDropCoordinates] = useState({
        lat: null,
        lng: null
    });
    const [data, setData] = useState([]);

    const [uniqueId, setUniqueId] = useState(1);

    const [deleteModal, setDeleteModal] = useState(false);
    const deleteToggle = () => {
        setDeleteModal(!deleteModal)
    };
    
    const getScheduleAsync = () => {
        dispatch(genericAction.callGenericGetterAsync('/schedule/admin/completed', (res) => {
            if (res?.data)
                setData(res?.data);
        }))
    }

    const loadPassengersAsync = () => {
        const {passengerNo} = formData;

        dispatch(genericAction.callGenericGetterAsync(`/users/${passengerNo}`, (res) => {
            if (res?.data)
                dispatch(actions.setSchedulePassengersList(res?.data));
        }))
        
    }

    const setFormData = (value, name) => {
        const producedData = produce(formData, drafState => {
            drafState[name] = value;
        })
        dispatch( actions.setScheduleFormData(producedData) );
    }

    useEffect(() => {
        getScheduleAsync();
    },[]);


    const handleDropSelect = async (value) => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        setDropAddress( value );
        setDropCoordinates(latLng);
        setFormData(value, 'dropLocation');
    }

    const removePassenger = (index) => {
      const newArr = passengersList?.map(passenger => passenger);
      newArr?.splice(index, 1);
      dispatch(actions.setScheduleUsers(newArr));
    }

    const saveScheduleHandler = () => {
        const payload =  {
            pNo: formData?.passengerNo,
            // pickLocation: data?.location_tmp,
            dropLocation: dropAddress,
            flightNumber: formData?.flightNumber,
            picklat: data?.lat,
            pickLong: data?.lng,
            droplat: dropCoordinates?.lat,
            droplong: dropCoordinates?.lng,
            status: formData?.rideStatus,
            pickDateTime: formData?.pickDateTime,
            passenger: passengersList,
        }
        
        dispatch(genericAction.callGenericAsync(payload, '/schedule', 'post', (res) => {
            if(res) {
              console.log(res);
            }
        }))
    }


    const deleteCustomerHandler = () => {
        if (uniqueId) {
          dispatch(genericAction.callGenericAsync({}, `/schedule/admin/${uniqueId}`, 'delete', (res) => {
            if(res) {
              getScheduleAsync();
            }
          }))
        }else {
          alert("Data not found.");
        }
    }

    const columns = [
        {
            name: "Flight Number",
            selector: "flightNumber",
            sortable: true
        },
        {
            name: "Drop Location",
            selector: "dropLocation",
            sortable: true,
        },
        {
            name: "Paid Amount",
            selector: "collectAmount",
            sortable: true
        },
        {
            name: "Ride Status",
            selector: (row) => {
              return 'Completed';
            },
            sortable: true
        },
        // {
        //   name: "Action",
        //     cell: (data) => {
        //       return (<i className="text-info fas fa-edit"  onClick={() => {
        //         const newData = data?.passenger?.map(passenger => passenger.pid);
                
        //         dispatch(actions.setScheduleUsers(newData));
        //         dispatch( actions.setScheduleFormData({
        //           flightNumber: data?.flightNumber,
        //           passengerNo: data?.pNo,
        //           dropLocation: data?.dropLocation,
        //           pickDateTime: DateTime.fromISO(data?.pickDateTime).toFormat("yyyy-mm-dd'T'hh:mm"),
        //           rideStatus: data?.status
        //         }) );
        //         setTemporaryData(data);
        //         setMode({
        //           edit: true
        //         });
        //         toggle();
        //       }}/>)
        //   },
        //   maxWidth: "5%",
        // }
    ];

    return (
        <>
        {appLoadingState ? <PageLoading /> :
        <PrivateTwoColumns pageTitle="Completed Rides">
            <Content>
                <Content.Header>
                  <Content.Title>Completed Rides</Content.Title>
                </Content.Header>
                <Content.Wrapper>
                <Card className="card-info card-outline">
                    <CardHeader>
                    <div>
                        <h3 class="card-title">Completed Rides Information</h3>
                    </div>
                    </CardHeader>
                    <CardBody>
                    <DataTableListing columns={columns} data={data} title="Completed Rides Listing" />
                    </CardBody>
                </Card>
                </Content.Wrapper>
                <ScheduleModal 
                    modal={modal} 
                    toggle={toggle} 
                    handleDropSelect={handleDropSelect} 
                    dropAddress={dropAddress}
                    setDropAddress={setDropAddress}
                    setFormData={setFormData}
                    loadPassengers={loadPassengersAsync}
                    passengersList={passengersList}
                    saveScheduleHandler={saveScheduleHandler}
                    temporaryData={temporaryData}
                    formData={formData}
                    mode={mode}
                    removePassenger={removePassenger}
                />
            </Content>
            <DeleteModal modal={deleteModal} toggle={deleteToggle} deleteCustomer={deleteCustomerHandler}/>
        </PrivateTwoColumns>
        }
        </>
    )
}

const mapStateToProps = ({ auth, schedule }) => {
    return {
      ...auth,
      ...schedule
    }
  }
  export default connect(mapStateToProps)(Schedule);