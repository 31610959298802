import React, {useState, useEffect} from 'react';
import PrivateTwoColumns from 'modules/layouts/PrivateTwoColumns';
import { connect } from 'react-redux';
import { Content } from 'adminlte';
import {
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import DataTableListing from 'pages/dataTable/DataTableListing';
import * as genericAction from 'generic/actions';
import PageLoading from 'components/page-loading/PageLoading';

function Billing({dispatch}) {
  const [appLoadingState, updateAppLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (data.length === 0) {
      getAsync();

    }
  }, [])

  const getAsync = () => {
    updateAppLoading(true);
    dispatch(genericAction.callGenericGetterAsync('/book/', (res) => {
      if (res) {
        setData(res?.data);
        updateAppLoading(false);
      }
    }));
  }

  const columns = [
    {
        name: "Title",
        selector: "title",
        sortable: true
    },
    {
        name: "Description",
        selector: "description",
        sortable: true,
    },
    {
        name: "ISBN",
        selector: "isbn",
        sortable: true
    },
  ];

  

  return (
    <>
    {appLoadingState ? <PageLoading /> :
      <PrivateTwoColumns pageTitle="Billing">
        <Content>
            <Content.Header>
              <Content.Title>Billing</Content.Title>
            </Content.Header>
            <Content.Wrapper>
              <Card className="card-info card-outline">
                <CardHeader>
                  <div>
                    <h3 class="card-title">Billing Information</h3>
                  </div>
                </CardHeader>
                <CardBody>
                  <DataTableListing columns={columns} data={data} title="Billing Listing" />
                </CardBody>
              </Card>
            </Content.Wrapper>
        </Content>
      </PrivateTwoColumns>
    }
    </>
  );
}


const mapStateToProps = ({ auth }) => {
  return {
    ...auth
  }
}
export default connect(mapStateToProps)(Billing);