import React from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import Main from '@modules/main/Main';
import Login from '@modules/login/Login';
import User from './User/containers/User';

import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';

import './App.scss';
import Schedule from 'schedule/containers/schedule/Schedule';
import SchedulePending from '@app/schedule/containers/schedule/Pending';
import ScheduleCompleted from 'schedule/containers/schedule/Completed';
import ScheduleUnassigned from 'schedule/containers/schedule/Unassigned';
import Driver from 'driver/containers/Driver';
import CompletedRides from 'schedule/containers/CompletedRides';
import Billing from 'billing/containers/Billing';
import Locations from 'locations/containers/Locations';
import { getToken, onMessageListener } from './firebase';

import {useState} from 'react';
import { toast } from 'react-toastify';

// import PersonalRides from '@app/schedule/containers/personalRides/PersonalRides';

const App = () => {

   // const [show, setShow] = useState(false);
    // const [notification, setNotification] = useState({title: '', body: ''});
    
    
    
    onMessageListener().then(payload => {
     // setShow(true);
      //setNotification({title: payload.notification.title, body: payload.notification.body})
      console.log('success: ',payload);
      const audio = new Audio('https://drive.google.com/uc?export=download&id=1M95VOpto1cQ4FQHzNBaLf0WFQglrtWi7');
      audio.play();
      toast(payload.data.message, {
        position: "top-right",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }).catch(err => console.log('failed: ', err));
      
    return (
        
        <Router>
            <Switch>
                <PublicRoute exact path="/">
                    <Login />
                </PublicRoute>
                <PublicRoute exact path="/login">
                    <Login />
                </PublicRoute>
                <PrivateRoute exact path="/dashboard">
                    <Main />
                </PrivateRoute>
                <PrivateRoute exact path="/schedule">
                    <Schedule />
                </PrivateRoute>
                <PrivateRoute exact path="/schedule-pending">
                    <SchedulePending />
                </PrivateRoute>
                <PrivateRoute exact path="/schedule-completed">
                    <ScheduleCompleted />
                </PrivateRoute>
                <PrivateRoute exact path="/schedule-unassigned">
                    <ScheduleUnassigned />
                </PrivateRoute>
                <PrivateRoute exact path="/personal-rides">
                    <Schedule />
                </PrivateRoute>
                <PrivateRoute exact path="/completed-rides">
                    <CompletedRides />
                </PrivateRoute>
                <PrivateRoute exact path="/user">
                    <User />
                </PrivateRoute>
                <PrivateRoute exact path="/drivers">
                    <Driver />
                </PrivateRoute>
                <PrivateRoute exact path="/billing-list">
                    <Billing />
                </PrivateRoute>
                <PrivateRoute exact path="/locations">
                    <Locations />
                </PrivateRoute>
            </Switch>
        </Router>
    );
};

export default App;
