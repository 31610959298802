import React, {useState} from 'react';
import {connect} from 'react-redux';
import {NavLink, Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Collapse} from 'reactstrap';

const MenuSidebar = ({user}) => {
    const [t] = useTranslation();
    const [collapseSchedule, setCollapseSchedule] = useState(false);
    const [collapsePersonal, setCollapsePersonal] = useState(false);
    const [collapseBilling, setCollapseBilling] = useState(false);
    const [collapseSettings, setcollapseSettings] = useState(false);

    let [isOpen, setIsOpen] = useState(true);

    const toggle = () => setIsOpen(!isOpen);
    const togglecollapseSchedule = () => setCollapseSchedule(!collapseSchedule);
    const togglecollapsePersonal = () => setCollapsePersonal(!collapsePersonal);
    const togglecollapseBilling = () => setCollapseBilling(!collapseBilling);
    const togglecollapseSettings = () => setcollapseSettings(!collapseBilling);

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <Link to="/" className="brand-link text-center">
                <span className="brand-text font-weight-dark">Khizar Admin</span>
            </Link>
            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img
                            src={user.picture || '/img/default-profile.png'}
                            className="img-circle elevation-2"
                            alt="User"
                        />
                    </div>
                    <div className="info">
                        <Link to="/profile" className="d-block">
                            {user.email}
                        </Link>
                    </div>
                </div>
                
                <nav className="mt-2" style={{overflowY: 'hidden'}}>
                    <ul
                        className="nav nav-pills nav-sidebar flex-column"
                        data-widget="treeview"
                        role="menu"
                        data-accordion="false"
                    >
                        <li className="nav-item">
                            <NavLink to="/dashboard" exact className="nav-link">
                                <i className="nav-icon fas fa-tachometer-alt" />
                                <p>{t('menusidebar.label.dashboard')}</p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/user" exact className="nav-link">
                                <i className="nav-icon fas fa-user" />
                                <p>Customers</p>
                            </NavLink>
                        </li>
                        
                        <li className="nav-item">
                            <NavLink to="/drivers" exact className="nav-link">
                                <i className="nav-icon fas fa-car" />
                                <p>Drivers</p>
                            </NavLink>
                        </li>
                        
                        <li className={`nav-item ${ collapseSchedule ? ' menu-open' : '' }`}>
                            <a href="#scheduleRides" className={`nav-link ${ collapseSchedule ? ' active': '' }`} onClick={ togglecollapseSchedule }>
                            <i className={`nav-icon fas fa-calendar`} />
                            <p>
                            Schedule Rides
                                <i className="right fas fa-angle-left" />
                            </p>
                            </a>
                            <Collapse isOpen={ collapseSchedule }>
                                <ul className={`nav nav-treeview`} style={{display: 'block'}}>
                            
                                    <li className="nav-item">
                                        <NavLink to="/schedule" exact className={`nav-link`}>
                                            All Schedules
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/schedule-pending" exact className={`nav-link`}>
                                            Pending Schedules
                                        </NavLink>
                                    </li>
                                    <li >
                                        <NavLink to="/schedule-completed" exact className={`nav-link`}>
                                            Completed Schedules
                                        </NavLink> 
                                    </li>
                                    <li >
                                        <NavLink to="/schedule-unassigned" exact className={`nav-link`}>
                                        Change Schedules Driver 
                                        </NavLink> 
                                    </li>
                                        
                                </ul>
                            </Collapse>
                        </li>

                        <li className={`nav-item${ collapsePersonal ? ' menu-open' : '' }`}>
                            <a href="#rides" className={`nav-link${ collapsePersonal ? ' active': '' }`} onClick={ togglecollapsePersonal }>
                            <i className={`nav-icon fas fa-calendar`} />
                            <p>
                            Personal Rides
                                <i className="right fas fa-angle-left" />
                            </p>
                            </a>
                            <Collapse isOpen={ collapsePersonal }>
                                <ul className={`nav nav-treeview`} style={{display: 'block'}}>
                            
                                    {/* <li className="nav-item">
                                        <NavLink to="/schedule" className={`nav-link${ collapsePersonal ? ' active': '' }`}>
                                            Schedule Rides
                                        </NavLink>
                                    </li> */}
                                    <li className="nav-item">
                                        <NavLink to="/personal-rides" className={`nav-link${ collapsePersonal ? ' active': '' }`}>
                                            Running Rides
                                        </NavLink>
                                    </li>
                                    <li >
                                        <NavLink to="/completed-rides" className={`nav-link${ collapsePersonal ? ' active': '' }`}>
                                            Completed Rides
                                        </NavLink> 
                                    </li>
                                        
                                </ul>
                            </Collapse>
                        </li>
                        {/* <li className={`nav-item${ collapseBilling ? ' menu-open' : '' }`}>
                            <a href="#billing" className={`nav-link${ collapseBilling ? ' active': '' }`} onClick={ togglecollapseBilling }>
                            <i className={`nav-icon fas fa-calendar`} />
                            <p>
                            Billing
                                <i className="right fas fa-angle-left" />
                            </p>
                            </a>
                            <Collapse isOpen={ collapseBilling }>
                                <ul className={`nav nav-treeview`} style={{display: 'block'}}>
                                    <li >
                                        <NavLink to="/billing-list" className={`nav-link${ collapseBilling ? ' active': '' }`}>
                                            Billing List
                                        </NavLink>
                                    </li>
                                    <li >
                                        <NavLink to="/personal-rides" className={`nav-link${ collapseBilling ? ' active': '' }`}>
                                            Track Wallet Transaction
                                        </NavLink>
                                    </li>
                                </ul>
                            </Collapse>
                        </li> */}

                        <li className={`nav-item${ collapseSettings ? ' menu-open' : '' }`}>
                            <a href="#settings" className={`nav-link${ collapseSettings ? ' active': '' }`} onClick={ togglecollapseSettings }>
                            <i className={`nav-icon fas fa-calendar`} />
                            <p>
                            Settings
                                <i className="right fas fa-settings" />
                            </p>
                            </a>
                            <Collapse isOpen={ collapseSettings }>
                                <ul className={`nav nav-treeview`} style={{display: 'block'}}>
                                    <li >
                                        <NavLink to="/locations" className={`nav-link${ collapseSettings ? ' active': '' }`}>
                                            Area Codes
                                        </NavLink>
                                    </li>
                                </ul>
                            </Collapse>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

export default connect(mapStateToProps, null)(MenuSidebar);
